// Feature flags
const ENABLE_BANNER = true;

// Configuration
const CONFIG = {
  REDIRECT_MAP: {
    "test.smileidentity.com": "https://portal.usesmileid.com",
    "prod.smileidentity.com": "https://portal.usesmileid.com",
    "portal.smileidentity.com": "https://portal.usesmileid.com",
    "redirect-portal.dev.smileid.co": "https://portal.dev.smileid.co",
  },
  COUNTDOWN_SECONDS: 5,
};

(function () {
  const getRedirectUrl = () => {
    const currentHost = window.location.host;
    return CONFIG.REDIRECT_MAP[currentHost]
      ? `${CONFIG.REDIRECT_MAP[currentHost]}${window.location.pathname}${window.location.search}`
      : null;
  };

  // Utility functions
  const createElement = (tag, className = "", attributes = {}) => {
    const element = document.createElement(tag);
    if (className) element.className = className;
    Object.entries(attributes).forEach(([key, value]) =>
      element.setAttribute(key, value),
    );
    return element;
  };

  const createIcon = () => {
    return createElement("img", "si-redirect-banner__icon", {
      src: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5NiA5NiI+PGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+PHBhdGggZD0iTTU0Ljk2NjIgNDcuNDI4M0gzVjk2LjAwMDFINTQuOTY2MlY0Ny40MjgzWiIgZmlsbD0iIzAwMTA5NiIvPjxwYXRoIGQ9Ik02Ny42MDIgMEM2Ny4zNjI5IDAgNjcuMTI5NiAwLjAyMzMzNjQgNjYuODkwNCAwLjAyOTE3MDVDNjYuNjUxMyAwLjAyMzMzNjQgNjYuNDE1MSAwIDY2LjE3NiAwQzUyLjkxNjEgMCA0MS4wODgxIDguNjQ2MTIgNDEuMDg4MSAyNy42OTc0VjQ3LjQ0MjhIOTIuOTE3M1YyNy42OTc0QzkyLjkxNzMgOC42NDYxMiA4MC45NDA2IDAgNjcuNjAyIDBaIiBmaWxsPSIjRkY5QjAwIi8+PC9nPjxkZWZzPjxjbGlwUGF0aCBpZD0iY2xpcDAiPjxyZWN0IHdpZHRoPSI5NiIgaGVpZ2h0PSI5NiIgZmlsbD0id2hpdGUiLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=",
      alt: "Smile ID Logo",
    });
  };

  const createCountdownWrapper = () => {
    const countdownWrapper = createElement(
      "div",
      "si-redirect-banner__countdown-wrapper",
    );
    const countdownSpan = createElement("span", "", {
      id: "redirect-countdown",
      "aria-live": "polite",
      "aria-atomic": "true",
    });
    countdownSpan.innerText = CONFIG.COUNTDOWN_SECONDS;
    const secondsSpan = createElement("span");
    secondsSpan.innerText = " seconds";
    countdownWrapper.append(countdownSpan, secondsSpan);
    return countdownWrapper;
  };

  const createCta = () => {
    const cta = createElement("span", "si-redirect-banner__cta");
    cta.innerHTML = `If you haven't been redirected, `;
    const clickHere = createElement("a", "", {
      href: getRedirectUrl(),
      role: "button",
      "aria-label": "Redirect to new domain immediately",
    });
    clickHere.innerHTML = `click here`;
    cta.appendChild(clickHere);
    return cta;
  };

  const createTextHalf = () => {
    const textHalf = createElement(
      "div",
      "si-redirect-banner__half si-redirect-banner__text-half",
    );
    const icon = createIcon();
    const text = createElement("div", "si-redirect-banner__text");
    text.innerText = "We have moved to a new domain!";
    const countdownWrapper = createCountdownWrapper();
    const cta = createCta();
    text.append(document.createElement("br"), countdownWrapper);
    textHalf.append(icon, text, cta);
    return textHalf;
  };

  const createImageHalf = () => {
    const imageHalf = createElement(
      "div",
      "si-redirect-banner__half si-redirect-banner__image-half",
    );
    const image = new Image();
    image.src = "https://cdn.smileidentity.com/landing-page-feature.png";
    image.alt = "Redirect feature illustration";
    image.loading = "lazy";
    image.className = "si-redirect-banner__image";
    imageHalf.appendChild(image);
    return imageHalf;
  };

  const updateCountdown = (seconds) => {
    const countdownElement = document.getElementById("redirect-countdown");
    if (countdownElement) {
      countdownElement.innerText = seconds;
      countdownElement.setAttribute("aria-valuenow", seconds);
    }
  };

  const startCountdown = () => {
    let secondsLeft = CONFIG.COUNTDOWN_SECONDS;
    const countdownInterval = setInterval(() => {
      secondsLeft--;
      updateCountdown(secondsLeft);
      if (secondsLeft <= 0) {
        clearInterval(countdownInterval);
        redirectToNewDomain();
      }
    }, 1000);
  };

  const createBanner = () => {
    const banner = createElement("div", "si-redirect-banner", {
      role: "alert",
      "aria-live": "assertive",
    });
    const bannerContent = createElement("div", "si-redirect-banner__content");
    const textHalf = createTextHalf();
    const imageHalf = createImageHalf();
    bannerContent.append(textHalf, imageHalf);
    banner.appendChild(bannerContent);
    return banner;
  };

  const appendBanner = () => {
    if (document.body) {
      document.body.appendChild(createBanner());
      sessionStorage.setItem("bannerDisplayed", "true");
      startCountdown();
      trackAnalytics("Banner Displayed", getRedirectUrl());
    } else {
      setTimeout(appendBanner, 5);
    }
  };

  const trackAnalytics = (message, redirectUrl) => {
    if (window.analytics) {
      window.analytics.track(message, {
        old_domain: window.location.host,
        new_domain: redirectUrl,
      });
    }
  };

  const redirectToNewDomain = () => {
    const redirectUrl = getRedirectUrl();
    if (!redirectUrl) return;

    try {
      trackAnalytics("Domain Redirected", redirectUrl);
      window.location.replace(redirectUrl);
    } catch (error) {
      console.error(`Redirect failed: ${error.message}`);
    }
  };

  const handleRedirect = () => {
    const redirectUrl = getRedirectUrl();
    if (!redirectUrl) return;

    const bannerDisplayed = sessionStorage.getItem("bannerDisplayed");

    if (ENABLE_BANNER && !bannerDisplayed) {
      appendBanner();
    } else {
      redirectToNewDomain();
    }
  };

  handleRedirect();
})();
