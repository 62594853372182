import React, { useState } from "react";
import useUrlState from "@ahooksjs/use-url-state";
import { ProColumns, ProTable } from "@ant-design/pro-components";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  ConfigProvider,
  Divider,
  Form,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { range, sortBy, uniqBy } from "lodash";
import { Link, useParams } from "react-router-dom";

import { PayCurrency } from "components/payment-plans/types";
import ThemeProvider from "components/ui/ThemeProvider";
import { useAdminPartnerId } from "hooks/useAdminPartnerId";
import { useFundingHistory, useUsageStatements, useWallet } from "queries";
import { buildCleanUrl } from "util/api_util";
import { canUserView } from "util/auth";
import { convertMoney } from "util/converters";
import { formatMoney } from "util/i18n";
import { getStringSorter } from "util/sorters";

interface TableRecord {
  date: Dayjs;
  amount: number;
  user: string | null;
  category: string;
  walletBalance: number;
}

const BillingHistory: React.FC = () => {
  const [adminPartnerId] = useAdminPartnerId();
  const { partner_id } = useParams<{ partner_id: string }>();
  const [currency, setCurrency] = useState<PayCurrency>(
    (sessionStorage.getItem("currency") as PayCurrency) || "USD",
  );
  const [currencyForm] = Form.useForm<{ currency: PayCurrency }>();
  const partnerId = adminPartnerId || partner_id;
  const [pagination, setPagination] = useUrlState({
    current: "1",
    pageSize: "10",
  });

  const { data: wallet } = useWallet(partnerId);
  const {
    data: fundingHistory,
    isLoading: isLoadingFundingHistory,
    refetch: refecthFundingHistory,
  } = useFundingHistory({ partnerId, fundingOnly: true, currency: "USD" });
  const { data: usageStatements, isLoading: isLoadingUsageStatements } =
    useUsageStatements({ partnerId }, { refetchInterval: 59 * 60 * 1000 });

  const downloadTransactions = (monthValue: number, yearValue: number) => {
    const date = dayjs().month(monthValue);
    date.set("year", yearValue);

    const from = date
      .startOf("month")
      .startOf("day")
      .format("YYYY-MM-DDThh:mm:ss");
    const to = date.endOf("month").endOf("day").format("YYYY-MM-DDThh:mm:ss");

    const params = {
      start_date: from,
      end_date: to,
      currency,
      token: localStorage.token,
      partner_id: partnerId,
    };
    const downloadLink = buildCleanUrl(`invoices/wallet_history`, params);
    window.open(downloadLink, "_blank");
  };

  const fundWalletButton = canUserView(["/partner/billing/payment"]) && (
    <Link
      to="?mode=change-payment-plan&step=add-funds"
      component={Button}
      size="large"
      type="primary"
    >
      Fund Wallet
    </Link>
  );

  const renderEmpty = () => (
    <div>
      <Typography.Paragraph>
        You have yet to fund your wallet.
      </Typography.Paragraph>
      {fundWalletButton}
    </div>
  );

  const columns: ProColumns<TableRecord, "text">[] = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      valueType: "dateTime",
      sorter: (a, b) => a.date.valueOf() - b.date.valueOf(),
      defaultSortOrder: "descend",
    },
    {
      title: "Total amount",
      dataIndex: "amount",
      key: "amount",
      renderText: (value) =>
        fundingHistory
          ? formatMoney(
              convertMoney(
                value,
                "USD",
                currency,
                fundingHistory?.exchangeRates,
              ),
              currency,
            )
          : "--",
      sorter: (a, b) => a.amount.valueOf() - b.amount.valueOf(),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      sorter: getStringSorter<Pick<TableRecord, "user">>("user"),
      filters: sortBy(
        uniqBy(
          fundingHistory?.walletTransactions
            .filter((t) => t.email)
            .map((t) => ({
              text: t.email!,
              value: t.email!,
            })),
          (x: { text: string; value: string }) => x.value,
        ),
        (x: { text: string; value: string }) => x.text,
      ),
      onFilter: (value, record) => record.user === value,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorter: getStringSorter<Pick<TableRecord, "category">>("category"),
      filters: sortBy(
        uniqBy(
          fundingHistory?.walletTransactions.map((t) => ({
            text: t.category,
            value: t.category,
          })),
          (x: { text: string; value: string }) => x.value,
        ),
        (x: { text: string; value: string }) => x.text,
      ),
      onFilter: (value, record) => record.category === value,
    },
    {
      title: "Wallet balance",
      dataIndex: "walletBalance",
      key: "walletBalance",
      renderText: (value) =>
        fundingHistory
          ? formatMoney(
              convertMoney(
                value,
                "USD",
                currency,
                fundingHistory?.exchangeRates,
              ),
              currency,
            )
          : "--",
    },
  ];

  const tableRecords = fundingHistory?.walletTransactions.map((t) => ({
    id: t.id,
    category: t.category,
    amount: convertMoney(
      t.amount,
      "USD",
      currency,
      fundingHistory.exchangeRates,
    ),
    user: t.email,
    date: dayjs(t.date),
    walletBalance: convertMoney(
      t.walletBalance,
      "USD",
      currency,
      fundingHistory.exchangeRates,
    ),
  }));

  return (
    <div>
      <Breadcrumb
        items={[
          { href: "/partner/billing", title: "Billing" },
          { title: "Wallet History" },
        ]}
      />
      <Form
        form={currencyForm}
        className="mt-6"
        layout="vertical"
        initialValues={{ currency }}
        onFinish={({ currency }) => setCurrency(currency)}
      >
        <Form.Item label="Currency" name="currency">
          <Select
            className="w-32"
            options={[
              { value: "USD", label: "🇺🇸 USD" },
              { value: "NGN", label: "🇳🇬 NGN" },
              { value: "KES", label: "🇰🇪 KES" },
              { value: "ZAR", label: "🇿🇲 ZAR" },
            ]}
            onChange={() => currencyForm.submit()}
            popupMatchSelectWidth={false}
            size="large"
          />
        </Form.Item>
      </Form>

      <Card
        title={
          <Typography.Title className="m-0" level={2}>
            Wallet Overview
          </Typography.Title>
        }
        role="region"
        aria-label="Wallet Overview"
      >
        <ThemeProvider isDarkMode>
          <div className="flex items-center gap-6 rounded-lg p-6 justify-between bg-[linear-gradient(45deg,#031532_-0.41%,#114482_122.81%,#02060b_122.81%)]">
            <Card
              className="bg-white bg-opacity-10"
              role="region"
              aria-label="Wallet balance"
            >
              <Typography.Text className="font-bold text-3xl">
                {wallet
                  ? formatMoney(
                      convertMoney(
                        wallet.walletBalance,
                        "USD",
                        currency,
                        wallet.exchangeRates,
                      ),
                      currency,
                    )
                  : "--"}
              </Typography.Text>
            </Card>
            <div className="grow">
              {fundingHistory &&
                fundingHistory.walletTransactions.length > 0 && (
                  <>
                    <Typography.Title className="text-sky-300 h4" level={3}>
                      Current Balance
                    </Typography.Title>
                    <Typography.Paragraph>
                      Last payment occurred on{" "}
                      {dayjs(fundingHistory.walletTransactions[0].date).format(
                        "MMMM DD, YYYY",
                      )}
                    </Typography.Paragraph>
                  </>
                )}
              {currency !== "USD" && (
                <Typography.Paragraph className="mb-0">
                  We record wallet balances in US dollars and display the
                  equivalent in local currency at the official exchange rate.
                </Typography.Paragraph>
              )}
            </div>
            <div>{fundWalletButton}</div>
          </div>
        </ThemeProvider>

        <Card className="mt-6">
          <div className="flex">
            <div className="grow">
              <div className="flex items-center justify-start gap-2">
                <Typography.Title level={3}>
                  Transaction History
                </Typography.Title>
                <Tag color="blue">CSV</Tag>
              </div>
              <Form
                layout="vertical"
                requiredMark={false}
                onFinish={({ period }) =>
                  downloadTransactions(
                    period.split("-")[0],
                    period.split("-")[1],
                  )
                }
              >
                <Row gutter={24} align="bottom">
                  <Col flex="1 1 auto">
                    <Form.Item name="period" label="Month" required>
                      <Select
                        options={range(7).map((i) => {
                          const date = dayjs().subtract(i, "months");
                          return {
                            label: date.format("MMMM, YYYY"),
                            value: `${date.month()}-${date.year()}`,
                          };
                        })}
                        placeholder="Select a month"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button htmlType="submit" size="large" type="primary">
                        Download
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <div>
              <Divider className="h-full m-4" type="vertical" />
            </div>
            <div className="grow">
              <div className="flex items-center justify-start gap-2">
                <Typography.Title level={3}>Usage Statement</Typography.Title>
                <Tag color="blue">PDF</Tag>
              </div>
              <Form
                layout="vertical"
                requiredMark={false}
                onFinish={({ period }) =>
                  window.open(usageStatements![period], "_blank")
                }
              >
                <Row gutter={24} align="bottom">
                  <Col flex="1 1 auto">
                    <Form.Item name="period" label="Month" required>
                      <Select
                        loading={isLoadingUsageStatements}
                        options={range(6).map((i) => {
                          const formattedDate = dayjs()
                            .subtract(i, "months")
                            .format("MMMM, YYYY");
                          return {
                            label: formattedDate,
                            value: formattedDate,
                            disabled: !usageStatements?.[formattedDate],
                          };
                        })}
                        placeholder="Select a month"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button htmlType="submit" size="large" type="primary">
                        Download
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Card>
      </Card>
      <div className="mt-6">
        <ConfigProvider renderEmpty={renderEmpty}>
          <ProTable<TableRecord>
            search={false}
            columns={columns}
            loading={isLoadingFundingHistory}
            cardBordered
            dataSource={tableRecords}
            columnsState={{
              persistenceKey: `${partnerId}:funding-history`,
              persistenceType: "localStorage",
            }}
            rowKey="id"
            pagination={{
              "aria-label": "Pagination",
              pageSize: Number(pagination.pageSize),
              current: Number(pagination.current),
              hideOnSinglePage: true,
              onChange: (current, pageSize) => {
                setPagination({ current, pageSize });
              },
              showSizeChanger: true,
              showQuickJumper: true,
            }}
            dateFormatter="string"
            headerTitle={
              <Typography.Title className="m-0" level={2}>
                Funding History
              </Typography.Title>
            }
            options={{ reload: () => refecthFundingHistory() }}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default BillingHistory;
