import { useState } from "react";
import { useBoolean, useInterval } from "ahooks";
import { Modal, Progress, Typography } from "antd";
import { useHistory } from "react-router-dom";

import useAuthenticatedUser from "hooks/useAuthenticatedUser";
import { useEnqueueReview } from "queries";

type Props = {
  onNextReview: () => void;
  reviewId: number;
  waitTime: number;
};

function TimeoutReview({ reviewId, onNextReview, waitTime }: Props) {
  const { userType } = useAuthenticatedUser();
  const history = useHistory();
  const [modalOpen, { setTrue: openModal, setFalse: closeModal }] =
    useBoolean();
  const [count, setCount] = useState(waitTime);

  const stopCountdown = useInterval(() => {
    if (count === 0) {
      stopCountdown();
      openModal();
      return;
    }

    setCount(count - 1);
  }, 1000);

  const { data } = useEnqueueReview({
    enabled: count === 0,
    id: reviewId,
  });

  const exitReview = () => {
    closeModal();
    const url =
      userType === "admin"
        ? "/admin/reviews/completed"
        : "/reviewer/reviews/completed";
    history.push(url);
  };

  return (
    <>
      <div>
        <Progress
          aria-label="Time left to review"
          type="circle"
          format={() => count}
          percent={((waitTime - count) / waitTime) * 100}
          size="small"
          status="active"
        />
      </div>
      <Modal
        title="Review Reassigned"
        open={modalOpen && data?.enqueued}
        onCancel={exitReview}
        maskClosable={false}
        okText="Yes"
        cancelText="No"
        onOk={onNextReview}
      >
        <Typography.Paragraph>
          Do you want to continue with another review?
        </Typography.Paragraph>
      </Modal>
    </>
  );
}

export default TimeoutReview;
