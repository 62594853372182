import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Card from "components/reusable/card";
import CountryPicker from "components/reusable/country_picker";
import {
  BodyItem,
  HeaderItem,
  Table,
  TableBody,
  TableHeader,
  TableRow,
} from "components/reusable/web_links/Table";
import { DisplayEnvironment } from "contexts/displayEnvironment";
import { useAdminPartnerId } from "hooks/useAdminPartnerId";
import Tick from "resources/img/icons/id_api.svg";
import RunAJobIcon from "resources/img/icons/play--blue.svg";
import RefreshIcon from "resources/img/icons/refresh.svg";
import WalletIcon from "resources/img/icons/wallet.svg";
import Warning from "resources/img/icons/warning.svg";
import {
  downloadUsageCSV,
  fetchIDStatusMetricsByPartner,
  fetchUsage,
  fetchWallet,
} from "util/api_util";
import { canUserView } from "util/auth";
import {
  convertCurrency,
  formatCurrency,
  sortCompare,
} from "util/format_helpers";
import { getUserType, jobTypeLookup } from "util/selectors";
import { getMonthsOptions } from "util/web_app_helpers";

function Wallet({
  userHasPaymentAccess,
  balance,
  currency,
  handleCurrencyChange,
  redirectTo,
}) {
  let walletBalance;
  if (balance.exchangeRates) {
    const convertedCurrency = convertCurrency(
      { state: { currency, ...balance } },
      balance.walletBalance,
    );
    walletBalance = formatCurrency(parseFloat(convertedCurrency), currency);
  }
  const tooltip = (
    <a
      href="https://docs.usesmileid.com/getting-started/fund-your-wallet"
      target="_blank"
      rel="noopener noreferrer"
    >
      Supported Payment Methods
    </a>
  );
  return (
    <Card
      cardClass="smile-card--centered dashboard__wallet dashboard__card"
      heading={walletBalance}
      icon={WalletIcon}
      text="Wallet Balance"
      buttonText={userHasPaymentAccess && "Fund Wallet"}
      redirectValue="billing/payment"
      onCardClick={redirectTo}
      dataTooltip={tooltip}
      disabled={!userHasPaymentAccess}
      selector={
        <div className="mb-2">
          <CountryPicker callback={handleCurrencyChange} currency={currency} />
        </div>
      }
    />
  );
}

function Dashboard() {
  const { environment } = useContext(DisplayEnvironment);
  const [currency, setCurrency] = useState(
    sessionStorage.getItem("currency") || "USD",
  );
  const [state, setState] = useState("loading");
  const [usages, setUsages] = useState({});
  const currentMonth = moment().format("MMMM YYYY");
  const [idStatus, setIdStatus] = useState("online");
  const [balance, setBalance] = useState({});
  const userType = getUserType();
  const [adminPartnerId] = useAdminPartnerId();
  const [duration, setDuration] = useState({
    label: currentMonth,
    value: currentMonth,
  });
  const [downloading, setDownloading] = useState(false);
  const [month, year] = duration.value.split(" ");
  const date = moment().month(month);
  date.set("year", year);
  const history = useHistory();

  const from = date?.startOf("month").format("YYYY-MM-DD");
  const to = date?.endOf("month").format("YYYY-MM-DD");
  const userHasWebAppAccess = canUserView(["/partner/web_app"]);
  const userHasBillingAccess = canUserView(["/partner/billing"]);
  const userHasPaymentAccess = canUserView(["/partner/billing/payment"]);

  useEffect(() => {
    getData();
  }, [environment]);

  const getData = () => {
    getWallet();
    getUsage(duration.value);
    getIDStatus();
  };

  const getIDStatus = () => {
    const params = {};
    if (userType === "admin") {
      params.partner_id = adminPartnerId;
    }
    fetchIDStatusMetricsByPartner(params).then((resp) => {
      let iDStatus = "online";
      if (resp && resp?.points) {
        resp?.points.forEach((point) => {
          if (point.unavailable > 0) {
            iDStatus = "interruption";
          }
        });
        setIdStatus(iDStatus);
      }
    });
  };

  const getWallet = () => {
    const params = {};
    if (userType === "admin") {
      params.partner_id = adminPartnerId;
    }
    fetchWallet(params).then((resp) => {
      setBalance({
        error: resp?.error,
        exchangeRates: resp?.exchange_rates,
        walletBalance: parseFloat(resp?.wallet_balance).toFixed(2),
      });
    });
  };

  const env = {
    false: "test",
    true: "production",
  }[environment];

  const onDataRefresh = () => {
    getUsage(duration.value);
  };

  const redirectTo = (event) => {
    sessionStorage.setItem("url", event.target.value);
    history.push(event.target.value);
  };

  const handleCurrencyChange = (currency) => {
    setCurrency(currency);
  };

  const handleUsageChange = () => (selectedDate) => {
    setDuration(selectedDate);
    getUsage(selectedDate.value);
  };

  const getUsage = (value) => {
    setState("loading");
    setUsages({});
    const [month, year] = value.split(" ");
    const date = moment().month(month);
    date.set("year", year);

    const from = date?.startOf("month").format("YYYY-MM-DD");
    const to = date?.endOf("month").format("YYYY-MM-DD");

    const params = {
      from,
      to,
      env,
    };

    if (userType === "admin") {
      params.partner_id = adminPartnerId;
    }

    fetchUsage(params).then((resp) => {
      if (!resp?.error) {
        if (resp) {
          setUsages((current) => ({
            ...current,
            ...resp,
          }));
        }
        setState("idle");
      } else {
        setState("error");
        setUsages({
          usageStatsError: resp?.error,
        });
      }
    });
  };

  const downloadJobsBreakdown = async () => {
    setDownloading(true);
    const params = {
      from,
      to,
      currency,
      env,
      token: localStorage.token,
    };

    if (userType === "admin") {
      params.partner_id = adminPartnerId;
    }
    const blob = await downloadUsageCSV(params);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `usage-breakdown-${from}-to-${to}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloading(false);
  };

  const showWallet = environment && userHasBillingAccess;

  let iDText = "All ID Authorities you have queried are online";
  let notice;
  if (idStatus !== "online") {
    notice = (
      <span className="not-applicable dashboard__id-status-notice">
        Notice:
      </span>
    );
    iDText = "ID service interrupted";
  }

  const durations = getMonthsOptions("MMMM YYYY");

  const sections = Object.entries(usages.sections ?? {});

  return (
    <>
      <div
        className={`legacy dashboard__card-container${environment ? "" : "--sandbox"}`}
      >
        <div className="smile-card dashboard__id-status dashboard__card">
          <div className="dashboard__id-status-header-container">
            <div className="dashboard__id-status-icon">
              <img
                className={
                  idStatus !== "online" ? "dashboard__id-status-warning" : ""
                }
                src={idStatus === "online" ? Tick : Warning}
                alt="status"
              />
            </div>
            <div className="newsmile-heading-h2 dashboard__id-status-header first-header">
              ID API Status
            </div>
            <div
              className={`newsmile-heading-h2 dashboard__id-status-header text--${idStatus}`}
            >
              {notice} {iDText}
            </div>
            <div
              className="btn btn-primary dashboard__id-status-button"
              onClick={() =>
                redirectTo({
                  target: { value: "id_Status" },
                })
              }
            >
              {idStatus === "online" ? "View" : "Check"} ID Status Page
            </div>
          </div>
        </div>
        <div className="dashboard-content">
          <div className="smile-card dashboard__usage dashboard__card">
            <div className="dashboard__usage-header-container">
              <div className="dashboard__usage-header-text">
                <div className="smile-card__header newsmile-heading-h2 dashboard__usage-header">
                  Usage for{" "}
                </div>
                <div className="smile-card__select">
                  <Select
                    onChange={handleUsageChange("duration")}
                    options={durations}
                    value={duration}
                    placeholder="Select duration"
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className="smile-card__link-container">
                <button
                  className="btn btn-primary smile-card__link"
                  onClick={downloadJobsBreakdown}
                  disabled={downloading}
                >
                  {downloading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {downloading ? " Downloading ..." : "Download Breakdown"}
                </button>
              </div>
            </div>
            <div>
              {state == "loading" && (
                <div className="loader loading-data-spinner loader--stats" />
              )}
              {sections.map((usage, key) => (
                <div className="dashboard__usage-table" key={key}>
                  <UsageTable
                    section={usage[0]}
                    data={usage[1]}
                    headers={usages.headers}
                  />
                </div>
              ))}
            </div>
            {state == "error" && (
              <div className="error">{usages.usageStatsError}</div>
            )}
          </div>
          <div className="dashboard-actions">
            {showWallet && (
              <Wallet
                balance={balance}
                userHasPaymentAccess={userHasPaymentAccess}
                handleCurrencyChange={handleCurrencyChange}
                currency={currency}
                redirectTo={redirectTo}
              />
            )}
            {userHasWebAppAccess && (
              <Card
                cardClass="smile-card--centered dashboard__web-app dashboard__card"
                heading="SmartCheck&trade;"
                icon={RunAJobIcon}
                text="Run any Smile Action without writing code"
                buttonText="Run a SmartCheck&trade;"
                redirectValue="web_app"
                onCardClick={redirectTo}
              />
            )}
          </div>
        </div>
      </div>

      <div className="dashboard__refresh-button" onClick={onDataRefresh}>
        <img src={RefreshIcon} alt="refresh-icon" /> Refresh Data
      </div>
    </>
  );
}

function UsageTable({ section, data, headers }) {
  const formatter = new Intl.NumberFormat();

  const jobs = data
    .map((job) => ({ ...job, product_name: jobTypeLookup(job.product) }))
    .sort((a, b) => sortCompare(a, b, "product_name"));
  return (
    <Table>
      <TableHeader>
        <HeaderItem>Product</HeaderItem>
        <HeaderItem>{headers[section].total}</HeaderItem>
        <HeaderItem>{headers[section].accepted}</HeaderItem>
        <HeaderItem>{headers[section].rejected}</HeaderItem>
      </TableHeader>
      <TableBody>
        {jobs.map((job) => (
          <TableRow key={job.product}>
            <BodyItem>{job.product_name}</BodyItem>
            <BodyItem>{formatter.format(job.total)}</BodyItem>
            <BodyItem className="dashboard__usage-table-accepted">
              {formatter.format(job.accepted)}
            </BodyItem>
            <BodyItem className="dashboard__usage-table-rejected">
              {formatter.format(job.rejected)}
            </BodyItem>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default connect(
  ({ enterprise: { partnerInfo } }) => ({ partnerInfo }),
  null,
)(Dashboard);
