import { Badge, Menu } from "antd";
import type { ItemType, MenuItemType } from "antd/es/menu/interface";
import { Link } from "react-router-dom";

import { useDisplayEnvironment } from "hooks/useDisplayEnvironment";
import { usePendingReviewCount, usePendingKYCReviewCount } from "queries";
import { userHasPermission } from "util/auth";

interface Props {}

const AdminNavMenu: React.FC<Props> = () => {
  const [_env, _setEnv, displayEnvironment] = useDisplayEnvironment();
  const hasPermission = userHasPermission(["partner full_read"]);
  const { data: pendingReviewCount } = usePendingReviewCount({
    refetchInterval: 30000,
  });
  const { data: pendingKYCReviewCount } = usePendingKYCReviewCount({
    refetchInterval: 30000,
    enabled: hasPermission,
  });

  const pathname = window.location.pathname.replace(/\/$/, "");

  const itemClassName = "transition-none";
  return (
    <Menu
      className="bg-transparent border-e-0"
      mode="inline"
      items={
        (
          [
            hasPermission && {
              key: "/admin/kyc_reviews",
              label: (
                <Link className={itemClassName} to="/admin/kyc_reviews">
                  <Badge
                    className="text-inherit"
                    classNames={{ indicator: "text-white" }}
                    color="red"
                    offset={[16, 0]}
                    count={pendingKYCReviewCount?.pending}
                  >
                    KYC Reviews
                  </Badge>
                </Link>
              ),
            },
            hasPermission && {
              key: "/admin/access_logs",
              label: (
                <Link className={itemClassName} to="/admin/access_logs">
                  Access Logs
                </Link>
              ),
            },
            {
              key: "/admin/reviews",
              label: (
                <Link className={itemClassName} to="/admin/reviews">
                  <Badge
                    className="text-inherit"
                    classNames={{ indicator: "text-white" }}
                    color="red"
                    offset={[16, 0]}
                    count={pendingReviewCount?.pending}
                  >
                    Open Reviews
                  </Badge>
                </Link>
              ),
            },
            {
              key: "/admin/reviews/completed",
              label: (
                <Link className={itemClassName} to="/admin/reviews/completed">
                  Completed Reviews
                </Link>
              ),
            },
            {
              key: "/admin/compare_reviews",
              label: (
                <Link className={itemClassName} to="/admin/compare_reviews">
                  Compare Reviews
                </Link>
              ),
            },
            {
              key: "/admin/docv_reviews",
              label: (
                <Link className={itemClassName} to="/admin/docv_reviews">
                  Document Verification Reviews
                </Link>
              ),
            },
            {
              key: "/admin/id_card_reviews",
              label: (
                <Link className={itemClassName} to="/admin/id_card_reviews">
                  ID Card Reviews
                </Link>
              ),
            },
            {
              key: "/admin/spoof_reviews",
              label: (
                <Link className={itemClassName} to="/admin/spoof_reviews">
                  Spoof Reviews
                </Link>
              ),
            },
            hasPermission && {
              key: "/admin/companies",
              label: (
                <Link className={itemClassName} to="/admin/companies">
                  Manage Partners
                </Link>
              ),
            },
            hasPermission && {
              key: "/admin/partner_dashboards",
              label: (
                <Link className={itemClassName} to="/admin/partner_dashboards">
                  View Partner Portal
                </Link>
              ),
            },
            hasPermission && {
              key: "/admin/sandbox/smile_links",
              label: (
                <Link
                  className={itemClassName}
                  to={`/admin/${displayEnvironment}/smile_links`}
                >
                  Smile Links
                </Link>
              ),
            },
            {
              key: "/admin/web_app",
              label: (
                <Link className={itemClassName} to="/admin/web_app">
                  SmartCheck™
                </Link>
              ),
            },
            hasPermission && {
              key: "/admin/analytics",
              label: (
                <Link className={itemClassName} to="/admin/analytics">
                  Analytics
                </Link>
              ),
            },
            hasPermission && {
              key: "/admin/id_status",
              label: (
                <Link className={itemClassName} to="/admin/id_status">
                  ID API Status
                </Link>
              ),
            },
            hasPermission && {
              key: "/admin/queue_health",
              label: (
                <Link className={itemClassName} to="/admin/queue_health">
                  Queue Health
                </Link>
              ),
            },
            hasPermission && {
              key: "/partner/account_settings",
              label: (
                <Link className={itemClassName} to="/partner/account_settings">
                  Account Settings
                </Link>
              ),
            },
            hasPermission && {
              key: "https://docs.usesmileid.com/",
              label: (
                <a
                  className={itemClassName}
                  href="https://docs.usesmileid.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Documentation
                </a>
              ),
            },
            hasPermission && {
              key: "/admin/permissions",
              label: (
                <Link className={itemClassName} to="/admin/permissions">
                  Permissions
                </Link>
              ),
            },
            hasPermission && {
              key: "/admin/reviewer_permissions",
              label: (
                <Link
                  className={itemClassName}
                  to="/admin/reviewer_permissions"
                >
                  Reviewer Permissions
                </Link>
              ),
            },
          ] as ItemType<MenuItemType>[]
        ).filter(Boolean) as ItemType<MenuItemType>[]
      }
      selectedKeys={[pathname]}
      defaultOpenKeys={["/reviews"]}
    />
  );
};

export default AdminNavMenu;
