import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface GetPendingKYCReviewCountResponse {
  pending: number;
}

const getPendingKYCReviewCount = async () => {
  const { data } = await axios.get("/company_kyc_details/pending");
  return data;
};

export const PENDING_KYC_REVIEW_COUNT_QUERY_KEY = "pending-kyc-review-count";

export const usePendingKYCReviewCount = (
  options?: Omit<
    UseQueryOptions<
      GetPendingKYCReviewCountResponse,
      AxiosError<{ errors: string[] }>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [PENDING_KYC_REVIEW_COUNT_QUERY_KEY],
    queryFn: getPendingKYCReviewCount,
    ...options,
  });
