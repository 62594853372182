import React, { useEffect, useState } from "react";
import moment from "moment";
import { fetchAntiFraudMatches } from "util/api_util";
import MatchingUser from "./matching_user";
import styles from "./smile_secure.module.css";

function SmileSecureResults({ user, jobIds, selfie, idCheck, partnerId }) {
  const [antiFraudMatches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchAntiFraudMatches(jobIds).then(({ matches }) => {
      setMatches(matches);
      setLoading(false);
    });
  }, [jobIds]);

  const lastEnrollment =
    antiFraudMatches.length > 0
      ? antiFraudMatches[0].enrolled_at
      : user.created_at;
  const date = new Date(lastEnrollment);
  const formattedTime = moment(date).format("DD-MM-YYYY h:mm a");

  const getValueOrDefault = (value) => {
    if (value === "" || value === undefined) {
      return "Not Provided";
    }
    return value;
  };

  if (loading) {
    return (
      <div className="loader__container">
        <div className="loader loader--centered" />
      </div>
    );
  }

  return (
    <div className={styles.smileSecureContainer}>
      <div className={styles.summaryContainer}>
        <div className={styles.summary}>
          <h3>Selfie Photo</h3>
          <p>
            User ID: <span>{user.partner_uid}</span>
          </p>
          <img src={selfie} alt="Selfie" />
        </div>
        <div className={styles.summary}>
          <h3>User Summary</h3>
          <p>Overview of user details</p>
          <div>
            <div className={styles.row}>
              <div>UserID</div>
              <div>{user.partner_uid}</div>
            </div>
            <div className={styles.row}>
              <div>Full Name</div>
              <div>{getValueOrDefault(idCheck?.name)}</div>
            </div>
            <div className={styles.row}>
              <div>DOB</div>
              <div>{getValueOrDefault(idCheck?.dob)}</div>
            </div>
            <div className={styles.row}>
              <div>Phone</div>
              <div>{getValueOrDefault(idCheck?.phone_number)}</div>
            </div>
            <div className={styles.row}>
              <div>Country</div>
              <div>{getValueOrDefault(idCheck?.country)}</div>
            </div>
            <div className={styles.row}>
              <div>ID Type</div>
              <div>{getValueOrDefault(idCheck?.id_type)}</div>
            </div>
            <div className={styles.row}>
              <div>ID Number</div>
              <div>{getValueOrDefault(idCheck?.id_number)}</div>
            </div>
            <div className={styles.row}>
              <div>Last Activity</div>
              <div>{formattedTime}</div>
            </div>
          </div>
        </div>
        <div className={[styles.summary, styles.smileSecure].join(" ")}>
          <h3>Smile Secure Summary</h3>
          <p>Evaluation of suspected fraud activity</p>
          <div className={styles.row}>
            <div>Has this user signed up to your service before?</div>
            <div>{antiFraudMatches.length > 0 ? "Yes" : "No"}</div>
          </div>
          <div className={styles.row}>
            <div>
              How many times had they signed up as of the last activity?
            </div>
            <div>{antiFraudMatches.length}</div>
          </div>
        </div>
      </div>
      {antiFraudMatches.length > 0 && (
        <div className={styles.suspects}>
          <div className={styles.suspectedUsersHeader}>
            <h2>Suspect Matching Users ({antiFraudMatches.length})</h2>
            <p>Keep track of matching users on your service</p>
          </div>
          <div className={styles.note}>
            <p>
              <b>Note:</b> Not Available means that the job submitted by the
              user was unsuccessful and did not return ID authority data, or did
              not contain this information (e.g. SmartSelfie™ Authentication)
            </p>
          </div>
          <div className={styles.matchingUsers}>
            {antiFraudMatches.map((match) => (
              <MatchingUser match={match} partnerId={partnerId} />
            ))}
            <br />
          </div>
        </div>
      )}
    </div>
  );
}

export default SmileSecureResults;
