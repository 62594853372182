import { useContext, useEffect } from "react";
import {
  DownOutlined,
  LeftOutlined,
  MenuOutlined,
  MoonOutlined,
  RightOutlined,
  SunOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ProConfigProvider } from "@ant-design/pro-components";
import { useBoolean, useLocalStorageState } from "ahooks";
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  Dropdown,
  Grid,
  Image,
  Layout,
  Menu,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import { removeEnterpriseState } from "actions/actions";
import { receiveToken } from "actions/session";
import ThemeProvider from "components/ui/ThemeProvider";
import { DisplayEnvironment } from "contexts/displayEnvironment";
import { useAdminPartnerId } from "hooks/useAdminPartnerId";
import useAuthenticatedUser from "hooks/useAuthenticatedUser";
import { useDisplayEnvironment } from "hooks/useDisplayEnvironment";
import { useLogOut } from "mutations";
import { usePartnerInfo } from "queries";
import logoDark from "resources/img/brand/logo-standard.svg";
import logoLight from "resources/img/brand/smile-id__dark-horizontal.svg";
import AdminNavMenu from "./AdminNavMenu";
import PartnerNavMenu from "./PartnerNavMenu";

interface Props {
  children: React.ReactNode;
  hasEnvSwitch?: boolean;
  hasDarkMode?: boolean;
}

const DashboardLayout: React.FC<Props> = ({
  children,
  hasEnvSwitch,
  hasDarkMode,
}) => {
  const screen = Grid.useBreakpoint();
  const location = useLocation();
  const [colorScheme, setColorScheme] = useLocalStorageState<"light" | "dark">(
    "colorScheme",
    {
      defaultValue: "light",
      listenStorageChange: true,
    },
  );
  const { environment, viewAs } = useContext(DisplayEnvironment);
  // if the url contains a param :environment, someone has followed a link
  // and we should make sure we are setting the component to the correct environment.
  // Changes in this value will result in the env slider state changing to the
  // correct value.
  const showProduction = environment === "production";
  const [env, setEnv] = useDisplayEnvironment();
  const isProduction = env || showProduction;
  const { userEmail, userType, permissionGroup } = useAuthenticatedUser();
  const hasSideBar =
    Boolean(userEmail) && ["admin", "partner"].includes(viewAs || "");
  const [siderOpen, { toggle: toggleSiderOpen, set: setSiderOpen }] =
    useBoolean(hasSideBar);
  const [
    menuDrawerOpen,
    { setTrue: openMenuDrawer, setFalse: closeMenuDrawer },
  ] = useBoolean();
  const [adminPartnerId] = useAdminPartnerId();
  const dispatch = useDispatch();
  const history = useHistory();
  const logOut = useLogOut({
    onSuccess: (data) => {
      localStorage.removeItem("token");
      localStorage.removeItem("adminPartnerId");
      dispatch(receiveToken(data));
      removeEnterpriseState(dispatch);
      history.push("/");
      window?.analytics?.reset();
    },
  });
  const { data: partnerInfo } = usePartnerInfo(
    { adminPartnerId },
    { enabled: viewAs === "partner" },
  );
  const homeUri =
    (typeof userType === "string" &&
      {
        admin:
          permissionGroup === "Operator"
            ? "/admin/reviews/completed"
            : "/admin/access_logs",
        partner: "/partner/dashboard",
      }[userType]) ||
    "/";

  useEffect(() => {
    if (!viewAs) {
      return;
    }
    setSiderOpen(["admin", "partner"].includes(viewAs));
  }, [viewAs]);

  useEffect(() => {
    if (viewAs === "unauthorized") {
      logOut.mutate();
    }
  }, [viewAs]);

  useEffect(() => {
    closeMenuDrawer();
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const navMenu = (
    <div className="bg-primary-blue px-2 py-6">
      {viewAs === "partner" && (
        <>
          {partnerInfo && (
            <div className="px-2">
              <div>
                <Typography.Text className="text-xl font-semibold">
                  {partnerInfo.partner.company.name}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className="text-xl font-semibold">
                  PartnerID: {partnerInfo.partner.smilePartnerId}
                </Typography.Text>
              </div>
              <Divider className="border-t-neutral-400" />
              {partnerInfo.partner.showNimcEnterpriseId && (
                <div>
                  <Typography.Text className="text-lg">
                    NIMC Enterprise Code: {partnerInfo.partner.nimcEnterpriseId}
                  </Typography.Text>
                </div>
              )}
            </div>
          )}
          <PartnerNavMenu />
        </>
      )}
      {viewAs === "admin" && <AdminNavMenu />}
    </div>
  );

  const isDarkMode =
    (colorScheme === "dark" && hasDarkMode) ||
    location.pathname === "/admin/reviews";

  return (
    <ThemeProvider isDarkMode={isDarkMode}>
      <ProConfigProvider dark={isDarkMode}>
        <Layout className="min-h-screen">
          <Layout.Header
            className={classNames(
              "fixed z-20 w-screen flex justify-between gap-4 px-4 md:px-8 lg:px-12 shadow-sm",
              { "max-sm:pl-0": hasSideBar },
            )}
          >
            <Space
              classNames={{ item: "h-full" }}
              size={screen.sm ? "large" : "middle"}
            >
              {hasSideBar && !screen.md && (
                <>
                  <ThemeProvider isDarkMode>
                    <Drawer
                      rootClassName="h-screen"
                      classNames={{
                        body: "p-0 bg-primary-blue",
                        header: "bg-primary-blue",
                      }}
                      open={menuDrawerOpen}
                      onClose={closeMenuDrawer}
                      placement="left"
                      title="Navigation Menu"
                    >
                      {navMenu}
                    </Drawer>
                  </ThemeProvider>
                  <Button
                    aria-label={menuDrawerOpen ? "Close menu" : "Open menu"}
                    type="text"
                    className="h-full max-sm:px-2 rounded-none"
                    onClick={openMenuDrawer}
                  >
                    <MenuOutlined role="presentation" />
                  </Button>
                </>
              )}
              <Link to={homeUri}>
                <Image
                  src={isDarkMode ? logoDark : logoLight}
                  alt="Home"
                  preview={false}
                />
              </Link>
            </Space>
            {(userEmail || hasDarkMode) && (
              <Space classNames={{ item: "h-full" }}>
                {userEmail && hasEnvSwitch && (
                  <Tooltip
                    title={
                      isProduction
                        ? "Toggle to change to Sandbox"
                        : "Toggle to change to Production"
                    }
                  >
                    <Switch
                      className={classNames({
                        "bg-products-green": isProduction,
                        "bg-products-orange": !isProduction,
                      })}
                      checkedChildren={screen.sm ? "Production" : "P"}
                      unCheckedChildren={screen.sm ? "Sandbox" : "S"}
                      checked={isProduction}
                      onChange={setEnv}
                    />
                  </Tooltip>
                )}
                {hasDarkMode && (
                  <Menu
                    mode="horizontal"
                    theme={isDarkMode ? "dark" : "light"}
                    items={[
                      {
                        key: "1",
                        onClick: () =>
                          setColorScheme(isDarkMode ? "light" : "dark"),
                        label: (
                          <Tooltip
                            title={
                              isDarkMode
                                ? "Switch to light theme"
                                : "Switch to dark theme"
                            }
                          >
                            {isDarkMode ? <SunOutlined /> : <MoonOutlined />}
                          </Tooltip>
                        ),
                      },
                    ]}
                  />
                )}
                {userEmail && (
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: "Log out",
                          onClick: () => logOut.mutate(),
                        },
                      ],
                    }}
                    placement="bottomRight"
                  >
                    <Button
                      className="h-full rounded-none px-1 max-sm:px-0"
                      type="text"
                    >
                      <Avatar icon={<UserOutlined aria-hidden />} />
                      <span className="max-md:hidden">{userEmail}</span>
                      <DownOutlined aria-hidden />
                    </Button>
                  </Dropdown>
                )}
              </Space>
            )}
          </Layout.Header>
          <Layout className="relative mt-16">
            {hasSideBar && (
              <ThemeProvider isDarkMode>
                <Layout.Sider
                  className="fixed bg-primary-blue max-md:hidden overflow-y-auto"
                  trigger={null}
                  collapsible
                  collapsed={!siderOpen}
                  collapsedWidth={0}
                  style={{
                    height: "calc(100vh - 4rem)",
                    scrollbarWidth: "thin",
                  }}
                  width={288}
                >
                  {navMenu}
                </Layout.Sider>
              </ThemeProvider>
            )}
            <Layout.Content
              className={classNames(
                "relative min-h-60 transition-all duration-200",
                {
                  "md:ms-72": hasSideBar && siderOpen,
                },
              )}
            >
              {hasEnvSwitch && (
                <div
                  className={classNames(
                    "fixed w-full z-20 ms-6 flex border-0 border-t-4 border-solid",
                    {
                      "border-t-products-green": isProduction,
                      "border-t-products-orange": !isProduction,
                    },
                  )}
                >
                  <div
                    className={classNames(
                      "uppercase font-semibold px-6 py-1 rounded-b-lg",
                      {
                        "bg-products-green": isProduction,
                        "bg-products-orange": !isProduction,
                      },
                    )}
                  >
                    {isProduction
                      ? "You are in Production"
                      : "You are in Sandbox"}
                  </div>
                </div>
              )}
              <div
                className={classNames("overflow-x-auto min-h-full", {
                  "p-6": hasSideBar,
                  "pt-16": hasEnvSwitch,
                })}
              >
                {children}
              </div>
            </Layout.Content>
            {hasSideBar && screen.md && (
              <Tooltip title={siderOpen ? "Close side bar" : "Open side bar"}>
                <Button
                  aria-label={siderOpen ? "Close side bar" : "Open side bar"}
                  className={classNames("fixed top-1/2", {
                    "left-2": !siderOpen,
                    "left-[16.75rem]": siderOpen,
                  })}
                  icon={siderOpen ? <LeftOutlined /> : <RightOutlined />}
                  onClick={toggleSiderOpen}
                  shape="circle"
                  size="large"
                />
              </Tooltip>
            )}
          </Layout>
        </Layout>
      </ProConfigProvider>
    </ThemeProvider>
  );
};

export default DashboardLayout;
