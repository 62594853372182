import { FC, ReactNode } from "react";
import { ConfigProvider, theme } from "antd";

import getTheme from "theme";

interface Props {
  isDarkMode?: boolean;
  children: ReactNode;
}

const ThemeProvider: FC<Props> = ({ isDarkMode, children }) => (
  <ConfigProvider
    theme={{
      algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
      ...getTheme(isDarkMode),
    }}
  >
    {children}
  </ConfigProvider>
);

export default ThemeProvider;
