import React, { useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import { Loader } from "components/reusable/Loader";
import PartnerSelector, {
  QueryState,
} from "components/reusable/reviews/ReviewPartnerSelector";
import styles from "components/reusable/reviews.module.css";
import ReviewsShow from "components/reusable/reviews_show";
import ThemeProvider from "components/ui/ThemeProvider";
import { DisplayEnvironment } from "contexts/displayEnvironment";
import { useReviews } from "queries";
import { buildState } from "util/api_util";

const Reviews: React.FC = () => {
  const history = useHistory();
  const { environment: displayEnvironment } =
    React.useContext(DisplayEnvironment);

  const [query, setQuery] = useState<QueryState>(() =>
    history?.location ? buildState(history.location.search) : {},
  );

  const {
    data: reviewsData,
    error,
    isLoading,
    refetch: refetchReviews,
  } = useReviews({
    filters: query as Record<string, string>,
    displayEnvironment,
  });

  return (
    <div
      className={classNames(
        "newsmile-container__main--temporarily-white dark_bg theme-dark",
      )}
    >
      <ThemeProvider isDarkMode>
        <div className={styles.partnerSelect}>
          <PartnerSelector query={query} setQuery={setQuery} />
          <ReviewsShow
            data={reviewsData?.reviews || []}
            error={error?.response?.data?.error}
            fetchReviews={refetchReviews}
            isLoading={isLoading}
          />
        </div>

        {isLoading && (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
            }}
          >
            <Loader size="xl" />
          </div>
        )}
      </ThemeProvider>
    </div>
  );
};

export default Reviews;
