import { ThemeConfig } from "antd";

const getTheme = (isDarkMode?: boolean): ThemeConfig => ({
  token: {
    fontFamily: "var(--font-default)",
    colorPrimary: isDarkMode ? "#8793fc" : "#151f72",
    fontSize: 16,
    colorPrimaryHover: isDarkMode ? "#abb4ff" : "#1f2da7",
    colorLink: isDarkMode ? "#8793fc" : "#151f72",
    colorLinkHover: isDarkMode ? "#abb4ff" : "#1f2da7",
    colorPrimaryActive: isDarkMode ? "#8793fc" : "#151f72",
    borderRadius: 6,
    colorPrimaryBg: "#cad6f5",
    colorSuccess: "#26a54f",
    colorSuccessBg: "#c9f5d0",
    colorSuccessBgHover: "#99eaa8",
    colorError: "#d1272a",
    colorWarning: isDarkMode ? "#ff9b00" : "#b16100",
    borderRadiusLG: 16,
  },
  components: {
    Button: {
      borderRadiusSM: 12,
      borderRadius: 16,
      borderRadiusLG: 20,
      primaryShadow: isDarkMode ? "none" : undefined,
    },
    DatePicker: {
      borderRadiusSM: 12,
      borderRadius: 20,
      borderRadiusLG: 20,
    },
    Descriptions: { itemPaddingBottom: 0 },
    Input: {
      borderRadiusSM: 12,
      borderRadius: 16,
      borderRadiusLG: 20,
    },
    InputNumber: {
      borderRadiusSM: 12,
      borderRadius: 16,
      borderRadiusLG: 20,
    },
    Card: {
      borderRadiusLG: 16,
    },
    Modal: {
      borderRadiusLG: 16,
    },
    Checkbox: {
      borderRadiusSM: 4,
    },
    Radio: {
      borderRadius: 16,
    },
    Select: {
      borderRadiusSM: 12,
      borderRadius: 36,
      borderRadiusLG: 20,
      paddingXXS: 6,
    },
    Segmented: {
      borderRadiusSM: 24,
      borderRadius: 24,
    },
    Tree: {
      borderRadiusSM: 4,
    },
    Layout: {
      headerBg: isDarkMode ? "#333" : "#fff",
      bodyBg: isDarkMode ? "#333" : "#f9f0e7",
    },
    Menu: {
      darkItemBg: isDarkMode ? undefined : "#fff",
      darkItemSelectedBg: isDarkMode ? undefined : "rgb(206,222,244)",
    },
    Tag: {
      borderRadiusSM: 16,
    },
  },
});

export default getTheme;
