import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { camelize } from "util/transformers";

interface WalletTransaction {
  id: string | null;
  date: string;
  jobId: string | null;
  email: string | null;
  amount: number;
  walletBalance: number;
  category: string;
}

interface GetFundingHistoryResponse {
  walletTransactions: WalletTransaction[];
  exchangeRates: {
    KES: number;
    ZAR: number;
    GHS: number;
    NGN: number;
    UGX: number;
    USD: number;
  };
}

interface GetFundingHistoryInput {
  fundingOnly?: boolean;
  partnerId?: string;
  currency?: string;
}

const getFundingHistory = async ({
  fundingOnly,
  partnerId,
  currency,
}: GetFundingHistoryInput): Promise<GetFundingHistoryResponse> => {
  const { data } = await axios.get("/invoices/funding_history", {
    params: { funding_only: fundingOnly, partner_id: partnerId, currency },
  });
  const transformedData = camelize<GetFundingHistoryResponse>(data);

  return {
    ...transformedData,
    walletTransactions: transformedData.walletTransactions.map((t) => ({
      ...t,
      amount: Number(t.amount),
      walletBalance: Number(t.walletBalance),
    })),
  };
};

export const FUNDING_HISTORY_QUERY_KEY = "funding-history";

export const useFundingHistory = (
  input: GetFundingHistoryInput,
  options?: Omit<
    UseQueryOptions<
      GetFundingHistoryResponse,
      AxiosError<{ errors: string[] }>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [FUNDING_HISTORY_QUERY_KEY, input],
    queryFn: () => getFundingHistory(input),
    ...options,
  });
