import React from "react";
import { Checkbox, Popover } from "antd";

function ErrorsControl({ open, name, errors, setSource, children }) {
  const handleChange = (event) => {
    const { checked, value } = event.target;
    setSource((prevSelectedItems) => {
      const updatedSet = new Set(prevSelectedItems);
      if (checked) {
        updatedSet.add(value);
      } else {
        updatedSet.delete(value);
      }
      return updatedSet;
    });
  };

  const options = [
    { value: "too_light_dark", label: "Too Light/Too Dark" },
    { value: "fraud", label: "Fraud" },
    { value: "blurry", label: "Photo Blurry" },
    { value: "image_unusable", label: "Photo Unusable" },
  ];

  const content = options.map((option, index) => (
    <div key={option.value}>
      <Checkbox
        value={option.value}
        name={name}
        checked={errors.has(option.value)}
        onChange={handleChange}
      >
        {option.label}
      </Checkbox>
    </div>
  ));

  return (
    <Popover content={content} open={open}>
      {children}
    </Popover>
  );
}

export default ErrorsControl;
