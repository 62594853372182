import React from "react";
import classNames from "classnames";

function AmlTable({
  data,
  columns,
  selectedIndex,
  tableClassName,
  activeClassName,
  onRowClick,
}) {
  return (
    <table className={classNames("legacy-table", tableClassName)}>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={`${column.name}-${index + 1}`}>{column.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr
            key={`${row.id}-${index + 1}`}
            className={index == selectedIndex ? activeClassName : ""}
            onClick={onRowClick ? () => onRowClick(index) : undefined}
          >
            {columns.map((column, colIndex) => (
              <td key={`${column.name}-${colIndex + 1}`}>{row[column.name]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default AmlTable;
