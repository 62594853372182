import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react";
import classNames from "classnames";
import styles from "./styled_button.module.css";

type ButtonOrAnchorProps = ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement>;

export interface StyledButtonProps extends ButtonOrAnchorProps {
  className?: string;
  dataTestId?: string;
  defaultButton: boolean;
  disabled?: boolean;
  isActive: boolean;
  variant?: "primary" | "ghost" | "outline" | string;
}

export function StyledButton({
  children,
  onClick,
  isActive,
  dataTestId,
  defaultButton,
  disabled,
  href,
  variant = "primary",
  className,
  ...props
}: StyledButtonProps) {
  const activeClass = isActive && !disabled ? styles.active : "";
  const defaultButtonClass =
    defaultButton && !disabled ? styles.defaultButton : "";

  const cssClassNames = classNames(
    {
      disableLink: href && disabled,
      "text-white hover:text-web-digital-blue": variant === "primary",
    },
    "h3",
    styles.btn,
    activeClass,
    defaultButtonClass,
    className,
  );

  if (href) {
    return (
      <a
        href={href}
        className={cssClassNames}
        data-variant={variant}
        data-testid={dataTestId}
        {...props}
      >
        {children}
      </a>
    );
  }
  return (
    <button
      className={cssClassNames}
      disabled={disabled}
      onClick={onClick}
      data-variant={variant}
      data-testid={dataTestId}
      {...props}
    >
      {children}
    </button>
  );
}
