import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface GetPendingReviewCountResponse {
  pending: number;
}

const getPendingReviewCount = async () => {
  const { data } = await axios.get("/reviews/pending");
  return data;
};

export const PENDING_REVIEW_COUNT_QUERY_KEY = "pending-review-count";

export const usePendingReviewCount = (
  options?: Omit<
    UseQueryOptions<
      GetPendingReviewCountResponse,
      AxiosError<{ errors: string[] }>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [PENDING_REVIEW_COUNT_QUERY_KEY],
    queryFn: getPendingReviewCount,
    ...options,
  });
