import { useQuery } from "@tanstack/react-query";

import { fetchPartnerInfo } from "util/api_util";
import { useAdminPartnerId } from "./useAdminPartnerId";
import useAuthenticatedUser from "./useAuthenticatedUser";

interface Partner {
  id: number;
  email: string;
  created_at: string;
  updated_at: string;
  smile_partner_id: string;
  company_id: number;
  name: string;
  phone: string | null;
  enrollee_id: string | null;
  status: string;
  group_id: number;
  encrypted_otp_secret: string | null;
  encrypted_otp_secret_iv: string | null;
  encrypted_otp_secret_salt: string | null;
  consumed_timestep: string | null;
  otp_required_for_login: string | null;
  otp_backup_codes: string | null;
  otp_secret: string | null;
  production_enabled: boolean;
  show_nimc_enterprise_id: string | null;

  [key: string]: object | string | number | boolean | null;
}

export interface PartnerInfo {
  partner: Partner;
}

function usePartnerInfo() {
  const [adminPartnerId] = useAdminPartnerId();
  const { userType } = useAuthenticatedUser();

  const queryResult = useQuery({
    queryKey: ["partnerInfo", userType, adminPartnerId],
    queryFn: async () => {
      const sortingParams =
        userType === "admin" && adminPartnerId
          ? { partner: adminPartnerId }
          : {};
      const partnerInfo = (await fetchPartnerInfo(sortingParams)) as
        | PartnerInfo
        | undefined;
      return partnerInfo;
    },
    enabled:
      userType === "partner" || !!(userType === "admin" && adminPartnerId),
  });

  return { partnerInfo: queryResult.data, userType, ...queryResult };
}

export default usePartnerInfo;
