import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface GetUsageStatementsResponse {
  [key: string]: string;
}

interface GetUsageStatementsInput {
  partnerId?: string;
}

const getUsageStatements = async ({
  partnerId,
}: GetUsageStatementsInput): Promise<GetUsageStatementsResponse> => {
  const { data } = await axios.get<GetUsageStatementsResponse>(
    "/usage_statements",
    {
      params: { partner_id: partnerId },
    },
  );

  return data;
};

export const USAGE_STATEMENTS_QUERY_KEY = "usage-statements";

export const useUsageStatements = (
  input: GetUsageStatementsInput,
  options?: Omit<
    UseQueryOptions<
      GetUsageStatementsResponse,
      AxiosError<{ errors: string[] }>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [USAGE_STATEMENTS_QUERY_KEY, input],
    queryFn: () => getUsageStatements(input),
    ...options,
  });
