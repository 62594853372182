import React, { useCallback, useEffect, useRef, useState } from "react";
import { ArrowsAltOutlined } from "@ant-design/icons";
import {
  IconLivePhoto,
  IconUserScan,
  IconUserSquare,
} from "@tabler/icons-react";
import { useBoolean, useClickAway, useKeyPress } from "ahooks";
import {
  Alert,
  Button,
  Card,
  Image,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import classNames from "classnames";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";

import ImageManipulationTools from "components/reusable/ImageManipulationTools";

interface DocumentImageViewerProps {
  imageUrls: string[];
  imageType:
    | "liveness"
    | "selfie"
    | "id_card"
    | "id_card_back"
    | "id_authority"
    | "id_authority_photo"
    | "id_authority_document";
  label?: string;
  source?: "selfie" | "agent";
  interval?: number;
  animate?: boolean;
}

const DocumentImageViewer: React.FC<DocumentImageViewerProps> = ({
  imageType,
  imageUrls,
  source,
  animate,
  interval = 250,
  label,
}) => {
  const [fullScreenOpen, { set: setFullScreenOpen, setTrue: openFullScreen }] =
    useBoolean();
  const [
    negativeFilter,
    { setFalse: resetNegativeFilter, toggle: toggleNegativeFilter },
  ] = useBoolean();
  const [
    brightnessFilter,
    { setFalse: resetBrightnessFilter, toggle: toggleBrightnessFilter },
  ] = useBoolean();
  const [
    fullScreenNegativeFilter,
    {
      setFalse: resetFullScreenNegativeFilter,
      toggle: toggleFullScreenNegativeFilter,
    },
  ] = useBoolean();
  const [
    fullScreenBrightnessFilter,
    {
      setFalse: resetFullScreenBrightnessFilter,
      toggle: toggleFullScreenBrightnessFilter,
    },
  ] = useBoolean();
  const [loading, setLoading] = useState(true);
  const [isAnimating, { setFalse: stopAnimating, toggle: toggleAnimating }] =
    useBoolean(animate);
  const [isActive, { setFalse: deactivate, setTrue: activate }] = useBoolean();
  const [error, setError] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const viewerRef = useRef<ReactZoomPanPinchContentRef>(null);
  useClickAway(deactivate, wrapperRef);
  const [fullScreenPositionX, setFullScreenPositionX] = useState(0);
  const [fullScreenPositionY, setFullScreenPositionY] = useState(0);
  const [flipX, { setFalse: resetFlipX, toggle: toggleFlipX }] = useBoolean();
  const [flipY, { setFalse: resetFlipY, toggle: toggleFlipY }] = useBoolean();
  const [rotation, setRotation] = React.useState(0);
  const [fullScreenRotation, setFullScreenRotation] = React.useState(0);
  const rotateAngle = 30;

  const rotateRight = useCallback(() => {
    setRotation((rotation) => (rotation + rotateAngle) % 360);
  }, [setRotation]);

  const rotateLeft = useCallback(() => {
    setRotation((rotation) => (rotation - rotateAngle) % 360);
  }, [setRotation]);

  const translateDistance = 40;

  const translateUp = () => {
    const t = viewerRef.current?.instance.transformState;
    if (!t) {
      return;
    }
    viewerRef.current!.setTransform(
      t.positionX,
      t.positionY - translateDistance,
      t.scale,
    );
  };

  const translateDown = () => {
    const t = viewerRef.current?.instance.transformState;
    if (!t) {
      return;
    }
    viewerRef.current!.setTransform(
      t.positionX,
      t.positionY + translateDistance,
      t.scale,
    );
  };

  const translateLeft = () => {
    const t = viewerRef.current?.instance.transformState;
    if (!t) {
      return;
    }
    viewerRef.current!.setTransform(
      t.positionX - translateDistance,
      t.positionY,
      t.scale,
    );
  };

  const translateRight = () => {
    const t = viewerRef.current?.instance.transformState;
    if (!t) {
      return;
    }
    viewerRef.current!.setTransform(
      t.positionX + translateDistance,
      t.positionY,
      t.scale,
    );
  };

  const reset = () => {
    viewerRef.current?.resetTransform();
    setRotation(0);
    resetFlipX();
    resetFlipY();
    resetNegativeFilter();
    resetBrightnessFilter();
  };

  const fullScreenReset = () => {
    setFullScreenRotation(0);
    resetFullScreenNegativeFilter();
    resetFullScreenBrightnessFilter();
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const timer = useRef<ReturnType<typeof setInterval> | undefined>(undefined);

  const enterFullScreen = () => {
    if (fullScreenOpen) {
      return;
    }
    setFullScreenRotation(0);
    openFullScreen();
  };

  const handleHotKey = (action: () => void) => () => {
    if (fullScreenOpen || isActive) {
      action();
      stopAnimating();
    }
  };

  useKeyPress("f", handleHotKey(enterFullScreen), {
    exactMatch: true,
    useCapture: true,
  });

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    if (!isAnimating || imageUrls?.length <= 1) return;

    timer.current = setInterval(nextImage, interval);

    return () => clearInterval(timer.current);
  }, [imageUrls, interval, isAnimating]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const handleImageLoad = () => {
    setLoading(false);
    setError(false);
  };

  const handleImageError = () => {
    setLoading(false);
    setError(true);
  };

  const selectImage = (index: number) => {
    stopAnimating();
    setCurrentImageIndex(index);
  };

  const imageDescriptionMap = {
    id_authority: {
      icon: <IconUserSquare size={24} />,
      description: "Image from ID authority",
    },
    id_authority_photo: {
      icon: <IconUserSquare size={24} />,
      description: "Image from ID authority",
    },
    id_authority_document: {
      icon: <IconUserSquare size={24} />,
      description: "Image from ID authority",
    },
    id_card: {
      icon: <IconUserSquare size={24} />,
      description: "Front of ID document",
    },
    id_card_back: {
      icon: <IconUserSquare size={24} />,
      description: "Back of ID document",
    },
    liveness: {
      icon: <IconLivePhoto size={24} />,
      description: "Liveness image",
    },
    selfie: {
      icon: <IconUserScan size={24} />,
      description: "User submitted selfie",
    },
    unknown: {
      icon: <IconUserScan size={24} />,
      description: "Unknown document type",
    },
  };

  const src = imageUrls?.[currentImageIndex];
  const imageDescription =
    imageDescriptionMap[imageType] || imageDescriptionMap.unknown;

  return (
    <div>
      <div
        className={classNames(
          "overflow-hidden border-2 border-neutral-off-white border-solid rounded-2xl bg-gradient-to-b",
          {
            "from-cyan-50 to-cyan-500": ["selfie", "liveness"].includes(
              imageType,
            ),
            "from-emerald-50 to-emerald-500": [
              "id_authority",
              "id_authority_photo",
              "id_authority_document",
            ].includes(imageType),
            "from-basic-lightergrey to-basic-lightergrey-soft": ![
              "selfie",
              "liveness",
              "id_authority",
              "id_authority_photo",
              "id_authority_document",
            ].includes(imageType),
          },
        )}
      >
        <div className="bg-white text-black flex justify-center align-middle gap-4 pt-1">
          <div>{imageDescription.icon}</div>
          <div>
            <Typography.Text className="text-black">
              {label || imageDescription.description}
            </Typography.Text>
          </div>
          {source && <Tag color="success">{source}</Tag>}
        </div>
        <div ref={wrapperRef} onClick={activate}>
          <TransformWrapper
            centerOnInit
            minScale={0.5}
            maxScale={10}
            panning={{ velocityDisabled: true }}
            wheel={{ activationKeys: ["Control"] }}
            disabled={error}
            ref={viewerRef}
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                <TransformComponent
                  wrapperClass={classNames("w-full h-full relative")}
                >
                  {loading && (
                    <div className="w-full h-full absolute top-0 left-0 grid place-items-center">
                      <Spin size="large" />
                    </div>
                  )}
                  <Image
                    alt={label || imageType}
                    className={classNames(
                      "transition-transform min-w-96 min-h-60 max-h-96 object-contain bg-transparent",
                      {
                        "invert-[2.5]": negativeFilter,
                        "brightness-[2.5]": brightnessFilter,
                      },
                    )}
                    onError={handleImageError}
                    onLoad={() => {
                      handleImageLoad();
                      resetTransform();
                    }}
                    onClick={toggleAnimating}
                    onContextMenu={() => false}
                    placeholder
                    preview={false}
                    src={src}
                    style={{
                      transform: `scaleX(${flipX ? -1 : 1}) scaleY(${flipY ? -1 : 1}) rotate(${rotation}deg)`,
                    }}
                    tabIndex={0}
                  />
                </TransformComponent>
                {error && (
                  <div className="grid place-items-center p-9">
                    <Card className="w-full max-w-96">
                      <Alert message="Error loading image" type="error" />
                    </Card>
                  </div>
                )}
                {!error && (
                  <ImageManipulationTools
                    onZoomIn={() => zoomIn(0.5)}
                    onZoomOut={() => zoomOut(0.5)}
                    onReset={() => {
                      reset();
                      resetTransform();
                    }}
                    onRotateLeft={rotateLeft}
                    onRotateRight={rotateRight}
                    onFlipHorizontal={toggleFlipX}
                    onFlipVertical={toggleFlipY}
                    onToggleNegativeFilter={toggleNegativeFilter}
                    onToggleBrightnessFilter={toggleBrightnessFilter}
                    onTranslateUp={translateUp}
                    onTranslateDown={translateDown}
                    onTranslateLeft={translateLeft}
                    onTranslateRight={translateRight}
                    isHotKeysActive={isActive && !fullScreenOpen}
                    extra={
                      <>
                        <Tooltip title="Full Screen (F)">
                          <Button
                            onClick={enterFullScreen}
                            icon={<ArrowsAltOutlined role="presentation" />}
                            aria-label="Full Screen"
                          />
                        </Tooltip>
                        <Image
                          className="hidden"
                          src={src}
                          preview={{
                            visible: fullScreenOpen,
                            scaleStep: 0.5,
                            minScale: 0.5,
                            maxScale: 10,
                            src,
                            onVisibleChange: setFullScreenOpen,
                            focusTriggerAfterClose: true,
                            destroyOnClose: true,
                            // eslint-disable-next-line react/no-unstable-nested-components
                            toolbarRender: (_, info) => (
                              <ImageManipulationTools
                                onZoomIn={info.actions.onZoomIn}
                                onZoomOut={info.actions.onZoomOut}
                                onFlipHorizontal={info.actions.onFlipX}
                                onFlipVertical={info.actions.onFlipY}
                                onRotateLeft={() =>
                                  setFullScreenRotation(
                                    (rotation) =>
                                      (rotation - rotateAngle) % 360,
                                  )
                                }
                                onRotateRight={() =>
                                  setFullScreenRotation(
                                    (rotation) =>
                                      (rotation + rotateAngle) % 360,
                                  )
                                }
                                onReset={() => {
                                  info.actions.onReset();
                                  fullScreenReset();
                                  setFullScreenPositionX(0);
                                  setFullScreenPositionY(0);
                                }}
                                onToggleBrightnessFilter={
                                  toggleFullScreenBrightnessFilter
                                }
                                onToggleNegativeFilter={
                                  toggleFullScreenNegativeFilter
                                }
                                isHotKeysActive
                                onTranslateUp={() =>
                                  setFullScreenPositionY(
                                    (posY) => posY - translateDistance,
                                  )
                                }
                                onTranslateDown={() =>
                                  setFullScreenPositionY(
                                    (posY) => posY + translateDistance,
                                  )
                                }
                                onTranslateLeft={() =>
                                  setFullScreenPositionX(
                                    (posX) => posX - translateDistance,
                                  )
                                }
                                onTranslateRight={() =>
                                  setFullScreenPositionX(
                                    (posX) => posX + translateDistance,
                                  )
                                }
                                stopAnimating={stopAnimating}
                              />
                            ),
                            onTransform: ({ transform: t }) => {
                              setFullScreenPositionX(t.x);
                              setFullScreenPositionY(t.y);
                            },
                            keyboard: true,
                            // eslint-disable-next-line react/no-unstable-nested-components
                            imageRender: (node, { transform: t }) =>
                              React.cloneElement(node, {
                                className: classNames(
                                  "transition-transform min-h-96 min-w-96",
                                  {
                                    "invert-[2.5]": fullScreenNegativeFilter,
                                    "brightness-[2.5]":
                                      fullScreenBrightnessFilter,
                                  },
                                ),
                                style: {
                                  ...node.props.style,
                                  transform: `translate3d(${fullScreenPositionX}px, ${fullScreenPositionY}px, 0px) rotate(${fullScreenRotation}deg) scaleX(${t.flipX ? -t.scale : t.scale}) scaleY(${t.flipY ? -t.scale : t.scale})`,
                                },
                              }),
                          }}
                        />
                      </>
                    }
                  />
                )}
              </>
            )}
          </TransformWrapper>
        </div>
        {imageUrls?.length > 1 && (
          <div className="flex justify-center gap-1 overflow-x-auto mb-4">
            {imageUrls.map((url: string, index: number) => (
              <button
                className={classNames(
                  "p-0 bg-transparent shadow-none border-2 border-solid overflow-hidden",
                  {
                    "border-transparent": index !== currentImageIndex,
                    "border-primary-blue": index === currentImageIndex,
                  },
                )}
                key={url}
              >
                <Image
                  src={url}
                  className="w-9 h-10 shrink-0 object-cover"
                  alt={`Thumbnail ${index + 1}`}
                  onClick={() => selectImage(index)}
                  preview={false}
                />
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentImageViewer;
