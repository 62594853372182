import React from "react";
import SortableHeader, {
  JobTypeType,
} from "components/reusable/SortableHeader";
import { useDisplayEnvironment } from "hooks/useDisplayEnvironment";
import { fetchPartners } from "util/api_util";

interface Props {
  clearSearch: () => void;
  endDate: string | undefined;
  filterByJobType: boolean;
  handleChange: (key: string, value: string | JobTypeType | null) => void;
  handleSubmit: () => void;
  jobType: JobTypeType | undefined;
  partner: { value: string };
  query: string | undefined;
  startDate: string | undefined;
  tableType: string;
}

function SortableHeaderContainer({
  clearSearch,
  endDate,
  filterByJobType,
  handleChange,
  handleSubmit,
  jobType,
  partner,
  query,
  startDate,
  tableType,
}: Props) {
  const [environment] = useDisplayEnvironment();
  const [partners, setPartners] = React.useState<string[]>([]);

  const fetchPartnersFn = async () => {
    try {
      const { partner_ids } = (await fetchPartners()) as {
        partner_ids: string[];
      };
      setPartners(partner_ids);
    } catch (error) {
      console.error("Failed to fetch partners:", error);
    }
  };

  React.useEffect(() => {
    (async () => {
      await fetchPartnersFn();
    })();
  }, [environment]);

  return (
    <SortableHeader
      partners={partners}
      clearSearch={clearSearch}
      endDate={endDate}
      filterByJobType={filterByJobType}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      jobType={jobType}
      partner={partner}
      query={query}
      startDate={startDate}
      tableType={tableType}
    />
  );
}

export default SortableHeaderContainer;
