import React from "react";
import { Descriptions, Typography } from "antd";
import { DescriptionsItemType } from "antd/es/descriptions";

import TimeoutReview from "components/reusable/TimeoutReview";
import { getEnvironment } from "contexts/displayEnvironment";
import { Review } from "types/review";

type ReviewHeaderProps = {
  review: Review;
  completed: boolean;
  title: string;
  onNextReview?: () => void;
};

const ReviewHeader: React.FC<ReviewHeaderProps> = ({
  title,
  review,
  completed,
  onNextReview = () => {},
}) => (
  <Typography.Text>
    <Typography.Title level={2}>{title}</Typography.Title>
    <Descriptions
      className="mb-6"
      items={(
        [
          {
            key: "1",
            label: <Typography.Text>Job ID</Typography.Text>,
            children: (
              <Typography.Link
                href={`/admin/job/${getEnvironment()}/${completed ? review.job.id : review.job_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {review.job.job_id}
              </Typography.Link>
            ),
          },
          {
            key: "2",
            label: <Typography.Text>Partner ID</Typography.Text>,
            children: review.job.partner_id,
          },
          {
            key: "3",
            label: <Typography.Text>First sent date</Typography.Text>,
            children: new Date(review.first_sent_at).toLocaleString(),
          },
          completed && {
            key: "4",
            label: <Typography.Text>Author</Typography.Text>,
            children: review.author,
          },
          !completed &&
            review.wait_time && {
              key: "4",
              label: <Typography.Text>Time left to review</Typography.Text>,
              children: (
                <TimeoutReview
                  waitTime={review.wait_time}
                  onNextReview={onNextReview}
                  reviewId={review.id}
                />
              ),
            },
        ] as DescriptionsItemType[]
      ).filter(Boolean)}
      layout="vertical"
      column={4}
      colon={false}
    />
  </Typography.Text>
);

export default ReviewHeader;
