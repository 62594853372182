import { jwtDecode } from "jwt-decode";

export const decode = (token?: string | null) => {
  if (!token) {
    return false;
  }

  try {
    return jwtDecode(token);
  } catch (e) {
    return false;
  }
};

export const COMMON_PARTNER_PATHS = [
  "/404",
  "/partner",
  "/partner/:country/:id_type",
  "/partner/account_settings",
  "/partner/analytics",
  "/partner/dashboard",
  "/partner/id_status",
  "/partner/job_list",
  "/partner/job_results/:environment/:id",
  "/partner/kyc/:environment/:userId",
  "/partner/support/tickets",
  "/partner/user_list",
  "/settings",
];

export const ACCESS_CONTROL_MAP = {
  auditor: {
    partner: COMMON_PARTNER_PATHS.concat([
      "/partner/billing",
      "/partner/billing/history",
    ]),
  },
  operator: {
    partner: COMMON_PARTNER_PATHS.concat(["/partner/web_app"]),
  },
};

export const userHasPermission = (permissions: string[]) => {
  const token = localStorage.getItem("token");
  const user = token
    ? (jwtDecode(token) as { permissions: string[] })
    : undefined;

  if (!user?.permissions) {
    return false;
  }

  // make a set out of the 2 permission arrays. If it is longer than the user's
  // permissions, than we know the user is missing at least one of the required permissions.
  const set = new Set(user.permissions.concat(permissions));
  return set.size === user.permissions.length;
};

export const canView = (
  user: { permission_group: "auditor" | "operator"; type: string },
  paths: string[],
) => {
  if (!user) return false;

  const userRole = user.permission_group.toLowerCase() as
    | "auditor"
    | "operator";
  const userType = user.type.toLowerCase() as "partner";
  const rolePaths = ACCESS_CONTROL_MAP[userRole];

  if (userRole && !rolePaths) return true;

  const allowedPaths = rolePaths[userType];

  if (!allowedPaths) return true;
  return paths.every((p) => allowedPaths.includes(p));
};

export const canUserView = (paths: string[]) => {
  const user = decode(localStorage.getItem("token")) as {
    permission_group: "auditor" | "operator";
    type: string;
  };
  return canView(user, paths);
};
