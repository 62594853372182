import { App, Button, Form, InputNumber, Radio, Row, Typography } from "antd";

import { useAdminPartnerId } from "hooks/useAdminPartnerId";
import { type CreateInvoiceResponse, useCreateInvoice } from "mutations";
import { convertMoney } from "util/converters";
import { formatCurrency, getCurrencySymbol } from "util/format_helpers";
import { getCurrencyName } from "util/i18n";
import { AutoPayConfig, PayCurrency } from "./types";

export const MIN_AMOUNT_USD = 20;

interface FormValues {
  currency: PayCurrency;
  amount: number;
}

interface Props {
  autoPay: AutoPayConfig;
  designatedCurrency?: PayCurrency;
  onCreateInvoice: (
    data: CreateInvoiceResponse & { currency: string; amount: number },
  ) => void;
}

const WalletTopUpForm: React.FC<Props> = ({
  autoPay,
  designatedCurrency,
  onCreateInvoice,
}) => {
  const { notification } = App.useApp();
  const [form] = Form.useForm<FormValues>();
  const currency = Form.useWatch("currency", form);
  const minAmount =
    autoPay.exchangeRates[currency || designatedCurrency] * MIN_AMOUNT_USD;
  const currencySymbol = getCurrencySymbol(currency);
  const [adminPartnerId] = useAdminPartnerId();

  const createInvoice = useCreateInvoice();

  const makePayment = async ({ amount, currency }: FormValues) => {
    createInvoice.mutate(
      {
        currency,
        partner_id: adminPartnerId,
        amount,
      },
      {
        onSuccess: (res) => {
          if (res.checkoutUrl) {
            window.location.assign(res.checkoutUrl);
          } else if (res.dlocalSfKey) {
            onCreateInvoice({ ...res, currency, amount });
          }
        },
        onError: (error) => {
          notification.error({ message: error.response?.data.error });
        },
      },
    );
  };

  return (
    <div>
      <Typography.Title level={3} className="h3">
        Top-up Your Wallet
      </Typography.Title>
      <Form
        form={form}
        initialValues={{ currency: designatedCurrency, amount: minAmount }}
        layout="vertical"
        onFinish={makePayment}
      >
        <Form.Item
          name="currency"
          label="Currency"
          rules={[{ required: true, message: "Currency is required" }]}
        >
          <Radio.Group
            className="flex flex-wrap justify-start gap-4"
            onChange={(e) => {
              form.setFieldsValue({
                amount: convertMoney(
                  form.getFieldValue("amount") || 0,
                  currency,
                  e.target.value as PayCurrency,
                  autoPay.exchangeRates,
                ),
              });
            }}
            size="large"
          >
            {autoPay.enabledCurrencies.map((currency) => (
              <Radio.Button
                className="rounded-full border-s before:hidden"
                key={currency}
                value={currency}
              >
                {currency} - {getCurrencyName(currency)}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="amount"
          label="Amount"
          rules={[
            { required: true, message: "Amount is required", type: "number" },
            {
              type: "number",
              min: minAmount,
              message: `Amount must be greater than ${formatCurrency(minAmount, currency)}`,
            },
          ]}
        >
          <InputNumber className="w-40" prefix={currencySymbol} size="large" />
        </Form.Item>

        <Form.Item>
          <Row justify="end">
            <Button
              htmlType="submit"
              loading={createInvoice.isPending}
              size="large"
              type="primary"
            >
              Proceed to Payment
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default WalletTopUpForm;
