import React from "react";
import Paginated from "containers/reusable/paginated";

export function TableV2(props) {
  const {
    columns = [],
    sortTableBy,
    onRowClick,
    enableRow,
    hidePagination,
    ...restProps
  } = props;

  const tableHeader = (
    <thead>
      <tr>
        {columns.map(
          ({ field, title, sortable = true, tooltip, sortBy = field }) => (
            <th
              key={field}
              className="joblist-table__head"
              scope="col"
              onClick={() => (sortable ? sortTableBy(sortBy) : undefined)}
            >
              <span {...{ "data-reverse-tooltip": tooltip }}>{title}</span>
              <button className="icon si-sort bg-transparent inline border-none shadow-none p-0" />
            </th>
          ),
        )}
      </tr>
    </thead>
  );
  const tableBody = (
    <tbody>
      {props.data.map((row, i) => (
        <tr
          onClick={() => onRowClick(row)}
          key={`row-${i}`}
          style={
            enableRow(row) ? { cursor: "pointer" } : { cursor: "notAllowed" }
          }
        >
          {columns.map(({ field, render }) => (
            <td key={`${field}-${i}`}>{render ? render(row) : row[field]}</td>
          ))}
        </tr>
      ))}
    </tbody>
  );
  return (
    <div className="joblist-table__container">
      <table className="legacy-table job-table">
        {tableHeader}
        {tableBody}
      </table>
      {!hidePagination && (
        <Paginated
          changeNumberOfItems={props.onItemCountChange}
          totalItems={props.totalPages}
          handlePageChange={props.onPageChange}
          itemsCountPerPage={props.itemCount}
          {...restProps}
        />
      )}
    </div>
  );
}
