import { useSessionStorageState } from "ahooks";
import type { Options } from "ahooks/lib/createUseStorageState";

export const useDisplayEnvironment = (options?: Options<boolean>) => {
  const [value, setValue] = useSessionStorageState<boolean>(
    "displayEnvironment",
    {
      listenStorageChange: true,
      ...options,
    },
  );
  return [
    value,
    setValue,
    value ? ("production" as const) : ("sandbox" as const),
  ] as const;
};
