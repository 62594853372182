import React, { Component, useState } from "react";
import ActionsInterface from "components/reusable/actions_interface";
import AmlResults from "pages/aml/aml-results";
import SmileSecureResults from "pages/kyc/smile_secure/smile_secure_results";
import logo from "resources/img/brand/logo-white-horizontal.svg";
import copiedIcon from "resources/img/icons/copied.svg";
import copyIcon from "resources/img/icons/copy.svg";
import emptyWarning from "resources/img/icons/empty-warning.svg";
import unknownImg from "resources/img/unknown_person.jpg";
import { buildKYCActions, getActionLabel } from "util/actions_helpers";
import { fetchSmileResults } from "util/api_util";
import { formatLocalisedDate, copy, isBase64 } from "util/format_helpers";
import { getUserType } from "util/selectors";

import ESignatureTable from "../e-signature/ESignatureTable";
import IdInfoAccordion from "../reusable/JobResultCard/IdInfoAccordion";
import Tabs, { Tab } from "../tabs";
import JobAndUserListTable from "./enterprise_dash/list_view/job_and_user_list_table";

function DisplayElement({ text }) {
  const [isShown, setIsShown] = useState(false);

  if (typeof text !== "string") {
    return "-";
  }

  text = text.trim();

  if (text.length === 0) {
    return "-";
  }

  if (text !== "pii_expired") {
    return text;
  }

  return (
    <div
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      style={{
        borderBottom: "1px dotted rgba(16, 24, 40, 0.5)",
        display: "inline-block",
      }}
    >
      {isShown ? "Outside Data Retention Period" : "Not Available"}
    </div>
  );
}

class JobResultCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.setActiveTab(props),
      activeMainTab: "overview",
      imageStatus: "loading",
      copied: false,
      showFullData: false,
      amlResults: {},
      amlResultsLoading: false,
    };
    this.JobIDcopy = this.JobIDcopy.bind(this);
    this.seeMore = this.seeMore.bind(this);
    this.getAmlJobId = this.getAmlJobId.bind(this);
    this.getAmlJob = this.getAmlJob.bind(this);
    this.hasAmlCheck = Boolean(this.getAmlJobId());
    this.hasESignature = Boolean(this.getESignatureJobId());
  }

  componentDidMount() {
    this.getAmlJob();
  }

  getAmlJobId() {
    const { jobListJobs } = this.props;
    const amlJob = jobListJobs.find((job) => job.product === "AML");
    return amlJob?.smile_job_id;
  }

  getESignatureJobId() {
    const { jobListJobs } = this.props;
    const eSignatureJob = jobListJobs.find(
      (job) => job.product === "Electronic Signature",
    );
    return eSignatureJob?.smile_job_id;
  }

  async getAmlJob() {
    const jobId = this.getAmlJobId();
    if (jobId) {
      this.setState({ amlResultsLoading: true });
      const { results } = await fetchSmileResults(`smile_results/${jobId}`);
      // for any job that has aml results, we only want the first result because only one aml result can exist per job.
      this.setState({ amlResults: results[0] });
      this.setState({ amlResultsLoading: false });
    }
  }

  setActiveTab() {
    return this.props.id_checks && this.props.id_checks.length > 0
      ? this.props.id_checks[0].id_type
      : "user";
  }

  showIDInfo() {
    const { id_checks = [] } = this.props;
    return id_checks.length > 0;
  }

  getMostRecentProvidedValue(key) {
    const { jobs = [] } = this.props;
    const job = jobs.find(
      ({ user_provided }) => user_provided?.[key]?.length > 0,
    );
    return String(job?.user_provided?.[key] || "").trim();
  }

  getMostRecentVerifiedValue(key) {
    const { id_checks = [] } = this.props;
    return String(
      id_checks.find((id_check) => id_check?.[key]?.length > 0)?.[key] || "",
    ).trim();
  }

  getValue(key) {
    const value = [
      this.getMostRecentVerifiedValue(key),
      this.getMostRecentProvidedValue(key),
    ].find((val) => val.length > 0);
    return <DisplayElement text={value} />;
  }

  getDate() {
    const { jobs = [] } = this.props;
    const jobDate = jobs[jobs.length - 1].created_at;
    return formatLocalisedDate(jobDate, "YYYY-MM-DD h:mm:ss A");
  }

  showUserInfo() {
    const { jobs = [] } = this.props;
    return jobs.some(
      ({ provided_phone, user_provided }) =>
        provided_phone || Object.values(user_provided ?? {}).some((val) => val),
    );
  }

  changeTab(label) {
    this.setState({ activeTab: label });
  }

  handleImageLoaded() {
    this.setState({ imageStatus: "loaded" });
  }

  handleImageErrored(e) {
    e.target.src = unknownImg;
  }

  isValidImage(img) {
    return img !== "/unknown_person.jpg";
  }

  JobIDcopy() {
    const textObj = copy("job-field__job-id", "Job Id copied to clipboard!");
    this.setState(textObj);
    this.setState({ copied: !this.state.copied });
  }

  seeMore() {
    this.setState({ showFullData: true });
  }

  displayFullData(fullData) {
    return Object.keys(fullData).map((key) => {
      if (!fullData[key]) {
        return null;
      }
      const value = fullData[key].toString().trim();
      if (value.length < 100) {
        return (
          <div className="job-field__container">
            <div className="job-field__label">
              {key.replace("_", " ").replace("-", " ")}
            </div>
            <div className="job-field__value">{value}</div>
          </div>
        );
      }
      if (isBase64(value)) {
        return (
          <div className="job-field__container">
            <div className="job-field__label">
              {key.replace("_", " ").replace("-", " ")}
            </div>
            <img
              src={`${
                fullData[key].includes("data:image") || value.includes("base64")
                  ? ""
                  : "data:image/jpeg;base64,"
              }${value}`}
              onLoad={this.handleImageLoaded.bind(this)}
              onError={this.handleImageErrored.bind(this)}
              className="icon--x-large"
              alt={key}
            />
          </div>
        );
      }
      return (
        <div className="job-field__container">
          <div className="job-field__label">
            {key.replace("_", " ").replace("-", " ")}
          </div>
          <div className="job-field__value">{value}</div>
        </div>
      );
    });
  }

  setUserDetails() {
    const userDetailsDivs = [];
    let names = new Set();
    let countries = new Set();
    let id_types = new Set();
    let id_numbers = new Set();
    let dobs = new Set();
    this.props.jobs.forEach((job) => {
      if (!job.user_provided) {
        return;
      }
      if (
        job.user_provided.name &&
        job.user_provided.name.match(/[a-zA-Z0-9]+/)
      ) {
        names.add(job.user_provided.name);
      }
      if (
        job.user_provided.country &&
        job.user_provided.country.match(/[a-zA-Z0-9]+/)
      ) {
        countries.add(job.user_provided.country);
      }
      if (
        job.user_provided.id_type &&
        job.user_provided.id_type.match(/[a-zA-Z0-9]+/)
      ) {
        id_types.add(job.user_provided.id_type);
      }
      if (
        job.user_provided.id_number &&
        job.user_provided.id_number.match(/[a-zA-Z0-9]+/)
      ) {
        id_numbers.add(job.user_provided.id_number);
      }
      if (
        job.user_provided.dob &&
        job.user_provided.dob.match(/[a-zA-Z0-9]+/)
      ) {
        dobs.add(job.user_provided.dob);
      }
    });
    names =
      names.size > 1
        ? Array.from(names).join(", ")
        : Array.from(names).toString();
    countries =
      countries.size > 1
        ? Array.from(countries).join(", ")
        : Array.from(countries).toString();
    id_types =
      id_types.size > 1
        ? Array.from(id_types).join(", ")
        : Array.from(id_types).toString();
    id_numbers =
      id_numbers.size > 1
        ? Array.from(id_numbers).join(", ")
        : Array.from(id_numbers).toString();
    dobs =
      dobs.size > 1 ? Array.from(dobs).join(", ") : Array.from(dobs).toString();
    userDetailsDivs.push(
      <div
        className="newsmile-heading-h1 job-result-section--details__title"
        key="name-header"
      >
        {names}
      </div>,
    );

    userDetailsDivs.push(
      <div
        className="job-field__container job-field__container--details-header"
        key="header"
      >
        <div className="job-field__label">Type</div>
        <div className="job-field__value">User Detail</div>
      </div>,
    );

    userDetailsDivs.push(
      <div key="name">
        <div className="job-field__container">
          <div className="job-field__label">Names</div>
          <div className="job-field__value">
            <DisplayElement text={names} />
          </div>
        </div>
      </div>,
    );

    if (countries || id_types || id_numbers || dobs) {
      userDetailsDivs.push(
        <div key="other">
          <div className="job-field__container">
            <div className="job-field__label">Country</div>
            <div className="job-field__value">
              <DisplayElement text={countries} />
            </div>
          </div>
          <div className="job-field__container">
            <div className="job-field__label">ID Type</div>
            <div className="job-field__value">
              <DisplayElement text={id_types} />
            </div>
          </div>
          <div className="job-field__container">
            <div className="job-field__label">ID Number</div>
            <div className="job-field__value">
              <DisplayElement text={id_numbers} />
            </div>
          </div>
          <div className="job-field__container">
            <div className="job-field__label">Date of Birth</div>
            <div className="job-field__value">
              <DisplayElement text={dobs} />
            </div>
          </div>
        </div>,
      );
    }

    return <div> {userDetailsDivs} </div>;
  }

  renderIDTabs() {
    const { id_checks = [] } = this.props;
    const tabs = new Set(
      id_checks.map(({ id_type }) => id_type).filter((id_type) => id_type),
    );
    return Array.from(tabs).map((tab) => (
      <div
        className={`job-result__tab ${
          this.state.activeTab === tab && "job-result__tab--active"
        }`}
        onClick={this.changeTab.bind(this, tab)}
        key={tab}
      >
        {tab.replace("_", " ")}
      </div>
    ));
  }

  renderIDInfo() {
    const { id_checks = [] } = this.props;
    const { activeTab } = this.state;

    let idInfo = id_checks.find(
      ({ id_type, result_code }) =>
        id_type === activeTab && result_code === "1012",
    );
    if (!idInfo) {
      idInfo = id_checks.find(({ id_type }) => id_type === activeTab);
    }

    if (idInfo) {
      const pii_expired =
        new Date() >= new Date(idInfo.data_retention_expires_at);
      return (
        <IdInfoAccordion
          idInfo={idInfo}
          piiDataExpired={pii_expired}
          strippedPii={idInfo.stripped_pii}
          originalIdphoto={idInfo.original_id_photo}
          jobType={idInfo.ran_as_job_type}
        />
      );
    }
  }

  render() {
    // IMPORTANT NOTE: When doing dev on this component, keep in mind that actions and source may not exist for all of the jobs (before 25-09-2019)
    let iDChecks = [];
    this.props.jobs.forEach((job) => {
      iDChecks = iDChecks.concat(job.id_checks);
    });
    const { actions } = this.props;
    const actionsDisplay = buildKYCActions(actions, this.props.jobs);

    const activities = new Set();
    this.props.jobs.forEach((job) => {
      activities.add(job.job_id);
    });
    const activityCount = activities.size;

    if (!this.props.user) {
      return (
        <div className="job-result__section">
          <div className="no-info__container">
            <img
              className="icon--large"
              src={emptyWarning}
              alt="No information to show"
            />
            <div className="no-info">No information to show</div>
          </div>
        </div>
      );
    }

    const imageLabels = { source: "Registered Selfie", target: "" };
    return (
      <div className="job-result__container">
        <Tabs
          activeTab={this.state.activeMainTab}
          onTabChange={(tab) => this.setState({ activeMainTab: tab })}
          variant="large"
        >
          <Tab id="overview" title="User Overview">
            <div className="job-result__card">
              <div className="job-result__row">
                <div className="job-result__section job-result__section--photos">
                  <div
                    className="photo__container"
                    style={
                      imageLabels.source
                        ? { visibility: "visible" }
                        : { visibility: "hidden" }
                    }
                  >
                    {this.state.imageStatus === "loading" && (
                      <div className="loader loading-data-spinner loading-data-spinner--photo" />
                    )}
                    <img
                      className="photo js-photo"
                      src={this.props.selfie}
                      onLoad={this.handleImageLoaded.bind(this)}
                      onError={this.handleImageErrored.bind(this)}
                      alt={imageLabels.source}
                    />
                    <div className="photo-tag">{imageLabels.source}</div>
                  </div>
                </div>

                <div className="job-result__section job-result__section--job-overview">
                  <div className="job-field__container">
                    <div className="job-field__label">User ID:</div>
                    <div className="job-field__value" id="job-field__job-id">
                      {this.props.user.partner_uid}
                    </div>
                    <div
                      onClick={this.JobIDcopy}
                      className="job-result__copy-button"
                    >
                      <img
                        src={this.state.copied ? copiedIcon : copyIcon}
                        alt="copy"
                      />
                      &nbsp;
                      <span className="copy">
                        {this.state.copied ? "Copied" : "Copy"}
                      </span>
                    </div>
                  </div>
                  <div className="job-field__container">
                    <div className="job-field__label">Full Name:</div>
                    <div className="job-field__value job-field__value-split">
                      <div>{this.getValue("name")}</div>
                    </div>
                  </div>
                  <div className="job-field__container">
                    <div className="job-field__label">DOB:</div>
                    <div className="job-field__value">
                      {this.getValue("dob")}
                    </div>
                  </div>
                  <div className="job-field__container">
                    <div className="job-field__label">Phone:</div>
                    <div className="job-field__value">
                      {this.getValue("phone_number")}
                    </div>
                  </div>
                  <div className="job-field__container">
                    <div className="job-field__label">Last Activity:</div>
                    <div className="job-field__value">{this.getDate()}</div>
                  </div>
                  <div className="job-field__container">
                    <div className="job-field__label">Activity Count:</div>
                    <div className="job-field__value">{activityCount}</div>
                  </div>
                </div>
              </div>

              <div className="job-result__row job-result__row--2">
                <div className="job-result__section job-result__section--ai">
                  <div className="job-result__section__header  job-result__section--ai__header job-result_section__header-image-align">
                    <div className="job-result__section--ai__header-image">
                      <img src={logo} alt="logo" />
                    </div>
                    <div>KYC Status</div>
                  </div>

                  {actions &&
                  Object.values(actionsDisplay).some(
                    (val) => val !== undefined,
                  ) ? (
                    <div>
                      {Object.keys(actionsDisplay).map((actionKey) => (
                        <ActionsInterface
                          keyLabel={getActionLabel(
                            actions.action_labels,
                            actionKey,
                          )}
                          keyName={actionKey}
                          value={actionsDisplay[actionKey]}
                          confidenceValue={null}
                          actionIcons={actions.action_icons}
                          heatmapActions={{
                            values: [],
                            keys: [],
                          }}
                          key={getActionLabel(actions.action_labels, actionKey)}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="job-result__section">
                      <div className="no-info__container">
                        <img
                          className="icon--large"
                          src={emptyWarning}
                          alt="No information to show "
                        />
                        <div className="no-info">No information to show</div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="job-result__section job-result__section--details">
                  <div className="kyc__section__header newsmile-heading-h1">
                    ID Information
                  </div>
                  <div className="job-result__section__header job-result__section--details__header">
                    {this.showIDInfo() && this.renderIDTabs()}
                    <div
                      className={`job-result__tab ${
                        this.state.activeTab === "user" &&
                        "job-result__tab--active"
                      }`}
                      onClick={this.changeTab.bind(this, "user")}
                    >
                      User Provided Info
                    </div>
                  </div>

                  {this.state.activeTab !== "user" &&
                    (iDChecks.length > 0 ? (
                      this.renderIDInfo()
                    ) : (
                      <div>
                        <div className="job-result__section">
                          <div className="no-info__container">
                            <img
                              className="icon--large"
                              src={emptyWarning}
                              alt="No information to show "
                            />
                            <div className="no-info">
                              No information to show
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  {this.state.activeTab === "user" && this.showUserInfo() && (
                    <div className="job-result-section-details__user-info">
                      {this.setUserDetails()}
                      {this.isValidImage(this.props.original_id_photo) &&
                        this.props.original_id_cards &&
                        this.props.original_id_cards.length > 0 && (
                          <div className="job-field__container">
                            {this.props.original_id_cards.map(
                              (original_id_photo) => (
                                <div>
                                  <div className="job-field__label">
                                    ID Card image
                                  </div>
                                  <div className="job-field__value">
                                    {this.state.imageStatus === "loading" && (
                                      <div className="loader loading-data-spinner loading-data-spinner--photo" />
                                    )}
                                    <img
                                      src={original_id_photo}
                                      onLoad={this.handleImageLoaded.bind(this)}
                                      onError={this.handleImageErrored.bind(
                                        this,
                                      )}
                                      className="icon--x-large"
                                      alt="ID card"
                                    />
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        )}
                    </div>
                  )}

                  {!this.showUserInfo() && this.state.activeTab === "user" && (
                    <div className="job-result__section">
                      <div className="no-info__container">
                        <img
                          className="icon--large"
                          src={emptyWarning}
                          alt="No information to show "
                        />
                        <div className="no-info">No information to show</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Tab>
          <Tab id="actions" title="User Actions">
            <div className="joblist">
              <JobAndUserListTable
                history={this.props.history}
                data={this.props.jobListJobs}
                userType={getUserType()}
                partnerId={this.props.partnerId}
                hidePagination
                pageName="job_list"
              />
            </div>
          </Tab>
          {this.hasESignature && (
            <Tab id="e-signature" title="eSignature">
              <div className="joblist">
                <ESignatureTable signatures={this.props.e_signatures} />
              </div>
            </Tab>
          )}
          {this.props.smile_secure_enabled && (
            <Tab id="secure" title="Smile Secure Results">
              <SmileSecureResults
                user={this.props.user}
                jobIds={this.props.jobs.map((job) => job.id)}
                selfie={this.props.selfie}
                idCheck={this.props.id_checks[0]}
                partnerId={this.props.partnerId}
              />
            </Tab>
          )}

          {this.hasAmlCheck && (
            <Tab id="aml" title="AML">
              {this.state.amlResultsLoading ? (
                <div className="loader" />
              ) : (
                <AmlResults
                  jobResult={this.state.amlResults}
                  showMonitoringDetails
                />
              )}
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}

export default JobResultCard;
