import { connect } from "react-redux";

import { fetchPartners } from "actions/actions";
import { receiveToken } from "actions/session";
import App from "App";

const mapDispatchToProps = (dispatch) => ({
  receiveToken: (token) => dispatch(receiveToken(token)),
  fetchPartners: () => fetchPartners(dispatch),
});

export default connect(null, mapDispatchToProps)(App);
