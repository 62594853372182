import {
  IconChartPie,
  IconCode,
  IconGraph,
  IconHeadset,
  IconId,
  IconLayoutDashboard,
  IconLink,
  IconList,
  IconPlayerPlay,
  IconReceiptDollar,
  IconSettings,
  IconUsers,
  IconUsersGroup,
} from "@tabler/icons-react";
import { Menu } from "antd";
import type { ItemType, MenuItemType } from "antd/es/menu/interface";
import { Link } from "react-router-dom";

import { useAdminPartnerId } from "hooks/useAdminPartnerId";
import useAuthenticatedUser from "hooks/useAuthenticatedUser";
import { useDisplayEnvironment } from "hooks/useDisplayEnvironment";
import { usePartnerInfo } from "queries";
import { canUserView, userHasPermission } from "util/auth";

const PartnerNavMenu: React.FC = () => {
  const [_env, _setEnv, displayEnvironment] = useDisplayEnvironment();
  const [adminPartnerId] = useAdminPartnerId();
  const { userType } = useAuthenticatedUser();
  const { data: partnerInfo } = usePartnerInfo({ adminPartnerId });
  const isAdmin = userType === "admin";
  const isPartner = userType === "partner";

  const pathname = window.location.pathname.replace(/\/$/, "");

  const jobListPath = isAdmin
    ? `/admin/partner_dashboards/${adminPartnerId}/job_list`
    : "/partner/job_list";
  const userListPath = isAdmin
    ? `/admin/partner_dashboards/${adminPartnerId}/user_list`
    : "/partner/user_list";

  const itemClassName = "transition-none";
  return (
    <Menu
      className="bg-transparent border-e-0"
      mode="inline"
      items={
        (
          [
            {
              key: "/partner/dashboard",
              icon: <IconLayoutDashboard />,
              label: (
                <Link className={itemClassName} to="/partner/dashboard">
                  Dashboard
                </Link>
              ),
            },
            isPartner && {
              key: "/partner/analytics",
              icon: <IconChartPie />,
              label: (
                <Link className={itemClassName} to="/partner/analytics">
                  Analytics
                </Link>
              ),
            },
            (isAdmin || isPartner) && {
              key: "/partner/developer_tools",
              icon: <IconCode />,
              label: (
                <Link className={itemClassName} to="/partner/developer_tools">
                  Developers
                </Link>
              ),
            },
            isAdmin ||
              (isPartner &&
                userHasPermission(["user_details read", "phone read"]) && {
                  key: jobListPath,
                  icon: <IconList />,
                  label: (
                    <Link className={itemClassName} to={jobListPath}>
                      Job List
                    </Link>
                  ),
                }),
            isAdmin ||
              (isPartner &&
                userHasPermission(["user_details read", "phone read"]) && {
                  key: userListPath,
                  icon: <IconUsers />,
                  label: (
                    <Link className={itemClassName} to={userListPath}>
                      User List
                    </Link>
                  ),
                }),
            isPartner &&
              userHasPermission(["selfie read", "id_card read"]) && {
                key: "/partner/web_app",
                icon: <IconPlayerPlay />,
                label: (
                  <Link className={itemClassName} to="/partner/web_app">
                    SmartCheck™
                  </Link>
                ),
              },
            (isAdmin || isPartner) && {
              key: `/partner/${displayEnvironment}/smile_links`,
              icon: <IconLink />,
              label: (
                <Link
                  className={itemClassName}
                  to={`/partner/${displayEnvironment}/smile_links`}
                >
                  Smile Links
                </Link>
              ),
            },
            (isAdmin || isPartner) && {
              key: "/partner/id_status",
              icon: <IconGraph />,
              label: (
                <Link className={itemClassName} to="/partner/id_status">
                  ID API Status
                </Link>
              ),
            },
            {
              key: "/partner/billing",
              icon: <IconReceiptDollar />,
              label: (
                <Link className={itemClassName} to="/partner/billing">
                  Billing
                </Link>
              ),
            },
            isPartner && {
              key: "/partner/account_settings",
              icon: <IconSettings />,
              label: (
                <Link className={itemClassName} to="/partner/account_settings">
                  Account Settings
                </Link>
              ),
            },
            isPartner && {
              key: "/partner/support/tickets",
              icon: <IconHeadset />,
              label: (
                <Link className={itemClassName} to="/partner/support/tickets">
                  Support
                </Link>
              ),
            },
            isPartner &&
              userHasPermission(["partner write"]) &&
              partnerInfo?.partner.company?.kycState === "accepted" &&
              partnerInfo?.partner.company?.ninState !== "accepted" && {
                key: "/partner/nin_v2",
                icon: <IconId />,
                label: (
                  <Link className={itemClassName} to="/partner/nin_v2">
                    Activate NIN Tokenization
                  </Link>
                ),
              },
            { type: "divider" },
            isPartner && {
              key: "/settings/team",
              icon: <IconUsersGroup />,
              label: (
                <Link className={itemClassName} to="/settings/team">
                  Invite Team Members
                </Link>
              ),
            },
          ] as ItemType<MenuItemType>[]
        ).filter(
          (item) =>
            item?.key &&
            canUserView([
              item.key
                .toString()
                .replace("/production/", "/:environment/")
                .replace("/sandbox/", "/:environment/"),
            ]),
        ) as ItemType<MenuItemType>[]
      }
      selectedKeys={[pathname]}
      defaultOpenKeys={["/reviews"]}
    />
  );
};

export default PartnerNavMenu;
