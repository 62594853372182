import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

export interface User {
  userEmail: string | null;
  userType: string | null;
  permissionGroup?: string | null;
}

function useAuthenticatedUser() {
  const [user, setUser] = useState<User>({ userEmail: null, userType: null });
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      try {
        const {
          email,
          type,
          permission_group: permissionGroup,
        } = jwtDecode(token) as {
          email: string | null;
          type: string | null;
          permission_group: string | null;
        };
        setUser({ userEmail: email, userType: type, permissionGroup });
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }
  }, [token]);

  return user;
}

export default useAuthenticatedUser;
