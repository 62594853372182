import React from "react";
import { Button, Space } from "antd";
import Select from "react-select";

import ThemeProvider from "components/ui/ThemeProvider";
import { getJobTypes } from "util/selectors";
import DateFilter from "../partner/enterprise_dash/list_view/date_filter";

interface PartnerSelectorProps {
  partners: string[];
  partner: { value: string };
  handleChange: (key: string, value: string | JobTypeType | null) => void;
}

function PartnerSelector({
  partners,
  partner,
  handleChange,
}: PartnerSelectorProps) {
  const partnerOptions = partners.map((partner) => ({
    value: partner.split(" - ")[0],
    label: partner,
  }));

  const selectedPartner = partnerOptions.find(
    (option) => option.value === partner?.value,
  );

  return (
    <Select
      value={selectedPartner}
      onChange={(option) => handleChange("partner", option)}
      options={partnerOptions}
      styles={{
        option: (provided) => ({ ...provided, color: "black" }),
        control: (provided) => ({ ...provided, width: 300 }),
      }}
      isClearable
      isLoading={partnerOptions.length === 0}
      placeholder="All Partners"
      isSearchable
    />
  );
}

export type JobTypeType = { label: string; value: string };

interface SortableHeaderProps extends PartnerSelectorProps {
  clearSearch: () => void;
  endDate: string | undefined;
  filterByJobType: boolean;
  handleSubmit: () => void;
  jobType: JobTypeType | undefined;
  query: string | undefined;
  startDate: string | undefined;
  tableType: string;
}

function SortableHeader(props: SortableHeaderProps) {
  const [dateToggle, setDateToggle] = React.useState(false);
  const onDateToggle = () => {
    setDateToggle(!dateToggle);
  };

  const searchByDate = () => {
    setDateToggle(!dateToggle);
    props.handleSubmit();
  };

  const hidePartner =
    props.tableType === "smile_results" || props.tableType === "actions";

  const dateFilter = (
    <DateFilter
      startDate={props.startDate}
      endDate={props.endDate}
      searchByDate={searchByDate}
      handleDateChange={props.handleChange}
      onDateToggle={onDateToggle}
      dateToggle={dateToggle}
    />
  );

  const jobTypes = getJobTypes();
  const selectedJobType = jobTypes.find(
    (option) => option.value === props.jobType?.value,
  );

  return (
    <div className="search__container search__container--attached">
      <form onSubmit={props.handleSubmit}>
        <input
          autoFocus
          className="search__input"
          type="text"
          placeholder="Search by Job ID"
          onChange={(e) => props.handleChange("query", e.target.value)}
          value={props.query}
        />
      </form>

      <div className="filters__controls">
        <div className="filters__container">
          <div className="filters__date__container">
            {hidePartner ? (
              <div className="" />
            ) : (
              <span className="filters__by inline">Filter by:</span>
            )}
            {dateFilter}
          </div>

          {!hidePartner && (
            <PartnerSelector
              partners={props.partners}
              partner={props.partner}
              handleChange={props.handleChange}
            />
          )}

          {props.filterByJobType && (
            <Select
              value={selectedJobType}
              onChange={(option) => props.handleChange("job_type", option)}
              options={jobTypes}
              styles={{
                option: (provided) => ({ ...provided, color: "black" }),
                control: (provided) => ({ ...provided, width: 300 }),
              }}
              isClearable
              isLoading={jobTypes.length === 0}
              placeholder="All Job Types"
              isSearchable
            />
          )}
        </div>

        <ThemeProvider>
          <Space>
            <Button onClick={props.handleSubmit} type="primary">
              Submit
            </Button>
            <Button onClick={props.clearSearch}>Clear Search</Button>
          </Space>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default SortableHeader;
