import React from "react";
import { useDeepCompareEffect } from "ahooks";
import { useHistory } from "react-router-dom";

import { Loader } from "components/reusable/Loader";
import { useSignUp } from "mutations";
import { useInvitation } from "queries";
import SmileLock from "resources/img/brand/smile-id__symbol--white.svg";

interface Props {
  match: { params: { invitation_id: string } };
}

const SignUp: React.FC<Props> = ({
  match: {
    params: { invitation_id },
  },
}) => {
  const history = useHistory();
  const [password, setPassword] = React.useState<string>();
  const [confirmPassword, setConfirmPassword] = React.useState<string>();
  const [formErrors, setFormErrors] = React.useState({
    password: {
      lowercase: false,
      uppercase: false,
      special: false,
      number: false,
      minimum: false,
    },
    confirmPassword: undefined as string | undefined | null,
  });

  const [message, setMessage] = React.useState<{
    type: "success" | "error";
    message: string;
  }>();
  const signUp = useSignUp();

  const checkIfFormValid = () => {
    if (
      Object.keys(formErrors.password).some(
        (k) => !formErrors.password[k as keyof typeof formErrors.password],
      )
    ) {
      return false;
    }

    if (
      formErrors.confirmPassword ||
      formErrors.confirmPassword === undefined
    ) {
      return false;
    }

    return true;
  };

  const validateField = (name: string, value: string) => {
    const passwordValidation = {
      lowercase: false,
      uppercase: false,
      special: false,
      number: false,
      minimum: false,
    };
    const specialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    const newFormErrors = { ...formErrors };

    if (name === "password") {
      passwordValidation.lowercase = value.search(/[a-z]/) >= 0;
      passwordValidation.uppercase = value.search(/[A-Z]/) >= 0;
      passwordValidation.special = specialChars.test(value);
      passwordValidation.number = value.search(/[0-9]/) >= 0;
      passwordValidation.minimum = value.length >= 8;
      newFormErrors.password = passwordValidation;
    }

    if (name === "confirmPassword") {
      if (password !== value) {
        newFormErrors.confirmPassword = "Passwords must match.";
      } else {
        newFormErrors.confirmPassword = null;
      }
    }

    setFormErrors(newFormErrors);
  };

  const onFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!invitation || !checkIfFormValid()) {
      return;
    }

    const data = {
      email: invitation.email,
      name: invitation.name,
      password: password!,
      invitation_id,
    };

    signUp.mutate(data, {
      onSuccess: () => {
        history.push("/");
      },
      onError: (e) => {
        if (e.response?.data) {
          setMessage({ type: "error", message: e.response.data.errors[0] });
        }
      },
    });
  };

  const {
    data: invitation,
    error: invitationError,
    isLoading: isLoadingInvitation,
  } = useInvitation({ id: invitation_id });

  useDeepCompareEffect(() => {
    if (invitationError?.response?.data) {
      setMessage({
        type: "error",
        message: invitationError.response.data.errors[0],
      });
    }
  }, [invitationError]);

  useDeepCompareEffect(() => {
    if (invitation && (!invitation.email || !invitation.name)) {
      setMessage({
        type: "error",
        message:
          "Invitation not found. Please contact your team's administrator.",
      });
    }
  }, [invitation]);

  return (
    <div className="legacy">
      <div className="newsmile-container__create-password">
        <div className="smile-create-password__container">
          <div className="smile-create-password__icon-container">
            <img
              className="smile-create-password__icon"
              src={SmileLock}
              alt="logo"
            />
          </div>
          {invitation && (
            <div className="smile-create-password__heading">
              {" "}
              Hi {invitation?.name || ""}, please complete your account setup
            </div>
          )}

          {message && (
            <div
              className={`message message--create-password ${
                message.type === "success"
                  ? "message--success"
                  : "message--error"
              }`}
            >
              {message.message}
            </div>
          )}

          <form
            className="smile-form smile-form--create-password"
            id="new_account"
            onSubmit={onFormSubmit}
          >
            <div className="field">
              <br />
              <label htmlFor="password">Password</label>
              <input
                autoComplete="new-password"
                aria-describedby="passwordErrors"
                type="password"
                id="password"
                name="password"
                className="input--line"
                required
                minLength={8}
                onChange={(e) => {
                  setPassword(e.target.value);
                  validateField(e.target.name, e.target.value);
                }}
                value={password}
              />

              <br />
              <br />

              <div id="passwordErrors" aria-live="polite">
                <div
                  className={
                    formErrors.password.lowercase ? "success" : "failure"
                  }
                >
                  {" "}
                  - One lowercase letter{" "}
                </div>
                <div
                  className={
                    formErrors.password.uppercase ? "success" : "failure"
                  }
                >
                  {" "}
                  - One uppercase letter{" "}
                </div>
                <div
                  className={
                    formErrors.password.special ? "success" : "failure"
                  }
                >
                  {" "}
                  - One special character{" "}
                </div>
                <div
                  className={formErrors.password.number ? "success" : "failure"}
                >
                  {" "}
                  - One number{" "}
                </div>
                <div
                  className={
                    formErrors.password.minimum ? "success" : "failure"
                  }
                >
                  {" "}
                  - 8 characters minimum{" "}
                </div>
              </div>
            </div>

            <div className="field">
              <br />
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                aria-describedby="confirmPasswordErrors"
                autoComplete="new-password"
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                className="input--line"
                required
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  validateField(e.target.name, e.target.value);
                }}
                value={confirmPassword}
              />
              <div
                id="confirmPasswordErrors"
                aria-live="polite"
                className="invalid-feedback"
                style={{
                  display: formErrors.confirmPassword ? "block" : "none",
                }}
              >
                {formErrors.confirmPassword}
              </div>
            </div>

            <br />
            <div className="button-container--center">
              <button
                className="btn btn-primary smile-login__button"
                disabled={signUp.isPending || isLoadingInvitation}
                type="submit"
              >
                {signUp.isPending && <Loader />}
                &nbsp;&nbsp;Create Account
              </button>
            </div>
          </form>
          <div className="fixed-dots--bottom-across" />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
