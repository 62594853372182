import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { connect, useDispatch } from "react-redux";
import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom";

import { removeEnterpriseState } from "actions/actions";
import { receiveToken } from "actions/session";
import { DashboardLayout } from "components/layout";
import { DisplayEnvironment } from "contexts/displayEnvironment";
import { useAdminPartnerId } from "hooks/useAdminPartnerId";
import { useDisplayEnvironment } from "hooks/useDisplayEnvironment";
import { useLogOut } from "mutations";
import { canUserView, decode, userHasPermission } from "./auth";

function Auth({ component: Component, path, token, preOTPUser, pageMeta }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const logOut = useLogOut({
    onSuccess: (data) => {
      localStorage.removeItem("token");
      localStorage.removeItem("adminPartnerId");
      dispatch(receiveToken(data));
      removeEnterpriseState(dispatch);
      history.push("/");
      window?.analytics?.reset();
    },
  });

  return (
    <Route
      path={path}
      render={(props) => {
        // for the create password page
        // I guess the components don't believe it exists if it wasn't there when
        // React began the update cycle, so we need to grab it from storage
        token = token || localStorage.token;
        preOTPUser = preOTPUser || localStorage.preOTPUser;

        const onTFALink =
          path === "/login/tfa" || path.includes("two-factor-authentication");

        if (preOTPUser && !onTFALink) {
          let user = preOTPUser;
          if (typeof preOTPUser === "string") {
            user = JSON.parse(preOTPUser);
          }
          const redirectURL = user.set_up_2FA
            ? "/set-up-two-factor-authentication"
            : "/login/tfa";

          return <Redirect to={redirectURL} />;
        }

        if (!token) {
          return (
            <>
              {pageMeta && (
                <Helmet>
                  {pageMeta.title && <title>{pageMeta.title}</title>}
                  {pageMeta.description && (
                    <meta name="description" content={pageMeta.description} />
                  )}
                  {pageMeta.robots && (
                    <meta name="robots" content={pageMeta.robots} />
                  )}
                </Helmet>
              )}
              <DashboardLayout>
                <Component {...props} />
              </DashboardLayout>
            </>
          );
        }

        const tokenInfo = decode(token);
        // there is no need to check if tokenInfo.exp exists, because
        // if it does not, the result will be NaN which will not
        // evaluate to be truthy when compared to 0.
        if (tokenInfo.exp - Date.now() / 1000 < 0) {
          logOut.mutate();
          return <Redirect to="/login" />;
        }
        // smileRedirect will be present if the user tried to access an authenticated view
        // page prior to logged in, or after being logged out.
        const smileRedirect = localStorage.getItem("smileRedirect");
        if (tokenInfo && smileRedirect) {
          localStorage.removeItem("smileRedirect");
          // if a partner is trying to follow a link to an admin page, any calls to the backend
          // would return a 401, so rather than allowing that, redirect to 404
          if (tokenInfo.type === "partner" && smileRedirect.includes("admin")) {
            return <Redirect to="/404" />;
          }
          return <Redirect to={smileRedirect} />;
        }
        const userType = tokenInfo.type;
        if (userType === "admin") {
          if (userHasPermission(["partner full_read"])) {
            return <Redirect to="/admin/access_logs" />;
          }
          return <Redirect to="/admin/reviews" />;
        }
        if (userType === "reviewer") {
          return <Redirect to="/reviewer" />;
        }
        if (userType === "partner") {
          if (window?.analytics?.identify) {
            window.analytics.alias(tokenInfo.user_id);
            window.analytics.identify(
              tokenInfo.user_id,
              {
                email: tokenInfo.email,
                company: {
                  id: tokenInfo.partner_id,
                  name: tokenInfo.partner_name,
                },
                user_type: tokenInfo.type,
                name: tokenInfo.name,
                createdAt: tokenInfo.created_at,
                sign_in_count: tokenInfo.sign_in_count,
              },
              {
                Intercom: {
                  hideDefaultLauncher: false,
                },
              },
            );

            window.intercomSettings = {
              custom_launcher_selector: "#open-intercom",
            };
          }
          return <Redirect to="/partner/dashboard" />;
        }
      }}
    />
  );
}

function Protected({
  component,
  path,
  token,
  enabledEnvToggle,
  viewAs,
  pageMeta,
  hasDarkMode,
  exact,
  ...props
}) {
  const [env] = useDisplayEnvironment();

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => (
        <Wrapper
          viewAs={viewAs}
          path={path}
          token={token}
          enabledEnvToggle={enabledEnvToggle}
          component={component}
          pageMeta={pageMeta}
          displayEnvironment={env}
          hasDarkMode={hasDarkMode}
          {...props}
        />
      )}
    />
  );
}

function Wrapper({
  component: Component,
  path,
  token,
  viewAs,
  enabledEnvToggle = false,
  pageMeta,
  hasDarkMode,
  ...restProps
}) {
  const context = useContext(DisplayEnvironment);
  const user = decode(localStorage.getItem("token"));
  const [adminPartnerId] = useAdminPartnerId();
  const history = useHistory();

  useEffect(() => {
    // since we explicitly set viewAs as 'admin' then we need to remove adminPartnerId from localStorage
    if (viewAs === "admin") {
      localStorage.removeItem("adminPartnerId");
    }

    // check validity of auth token between route switching
    // determine what sidebar navigation to display when we switch between routes
    let _viewAs;
    if (!user?.permissions) {
      _viewAs = "unauthorized";
    } else if (
      user.type === "partner" ||
      (user.type === "admin" && adminPartnerId)
    ) {
      _viewAs = "partner";
    } else if (user.type === "admin") {
      _viewAs = "admin";
    }
    context.setViewAs(viewAs || _viewAs);
  }, [adminPartnerId, viewAs]);

  useEffect(() => {
    if (viewAs === "partner" && user.type === "admin" && !adminPartnerId) {
      // set viewAs to admin to prevent indefinite loop while an navigating to admin/partner_dashboards path
      context.setViewAs("admin");
      history.replace("/admin/partner_dashboards");
    }
  }, [adminPartnerId, user.type]);

  // for the create password page
  const location = useLocation();

  if (!user?.permissions) {
    // if the user was trying to follow a link, or view a page and their token has expired
    // save the location so we can redirect them there after they log (back) in.
    localStorage.setItem(
      "smileRedirect",
      `${location.pathname}${location.search}`,
    );
    return <Redirect to="/login" />;
  }

  if (
    user.type === "partner" &&
    !canUserView(Array.isArray(path) ? path : [path])
  ) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <Helmet>
        <title>
          {pageMeta?.title
            ? `${pageMeta.title} - Smile ID Portal`
            : "Smile ID Portal"}
        </title>

        <meta
          name="description"
          content={
            pageMeta?.description ||
            `${pageMeta?.title ? `${pageMeta.title} - Smile ID Portal` : "Smile ID Portal"}`
          }
        />

        <meta name="robots" content="noindex" />
      </Helmet>

      <DashboardLayout
        hasEnvSwitch={enabledEnvToggle}
        hasDarkMode={hasDarkMode}
      >
        <Component {...restProps} key={path} />
      </DashboardLayout>
    </>
  );
}

const mapStateToProps = (state) => ({
  token: state.session.token,
  preOTPUser: state.session.preOTPUser,
});

export const AuthRoute = connect(mapStateToProps, null)(Auth);

export const ProtectedRoute = withRouter(
  connect(mapStateToProps, null)(Protected),
);
