import { FC } from "react";
import { Breadcrumb, Typography } from "antd";
import ApiKeyList from "components/reusable/SecuritySettings/ApiKeyList";
import CallbackList from "components/reusable/SecuritySettings/CallbackList";

const SecuritySettings: FC = () => (
  <>
    <Breadcrumb
      className="mb-5"
      separator=">"
      items={[
        {
          title: (
            <Typography.Text className="text-primary-blue">
              Developer Tools
            </Typography.Text>
          ),
        },
        {
          title: (
            <Typography.Text className="text-black">
              Security Settings
            </Typography.Text>
          ),
        },
      ]}
    />
    <ApiKeyList />
    {/* @ts-expect-error we expect env to have VITE_ENVIRONMENT */}
    {import.meta.env.VITE_CALLBACK_URL_ENABLE && <CallbackList />}
  </>
);

export default SecuritySettings;
