import { useEffect, useState } from "react";
import { useDeepCompareEffect, useExternal } from "ahooks";
import { App, Button, Col, Form, Input, Row, Select, Typography } from "antd";

import Loader from "components/ui/Loader";
import { useAdminPartnerId } from "hooks/useAdminPartnerId";
import { useCreatePaymentMethod } from "mutations";
import { usePaymentStatus } from "queries";
import { isProduction } from "util/environment";
import { getCountryName, getCurrencyCountry } from "util/i18n";

const dlocalInputStyle = {
  base: {},
  autofilled: {},
};

const supportedCountries = ["KE", "NG", "ZA"];

interface FormValues {
  name: string;
  documentId: string;
  cardId: string;
}

interface DLocalSaveCardFormProps {
  apiKey: string;
  currency: string;
  onSubmit?: () => void;
}

const DLocalSaveCardForm: React.FC<DLocalSaveCardFormProps> = ({
  apiKey,
  currency,
  onSubmit,
}) => {
  const [dLocalInstance, setDLocalInstance] = useState<DLocalInstance>();
  const { notification, message } = App.useApp();
  const [partnerId] = useAdminPartnerId();
  const [form] = Form.useForm<FormValues>();
  const fullName = Form.useWatch("name", form);
  const createPaymentMethod = useCreatePaymentMethod();
  const [paymentRef, setPaymentRef] = useState<string>();
  const country = getCurrencyCountry(currency);
  const [isPolling, setIsPolling] = useState(false);
  const { data: paymentStatusData } = usePaymentStatus(
    {
      country,
      payment_ref: paymentRef!,
      provider: "dlocal",
    },
    {
      enabled: Boolean(paymentRef),
      refetchInterval: () => (isPolling ? 3000 : false),
    },
  );

  const [cvv, setCVV] = useState<DLocalFieldInstance>();
  const [cardType, setCardType] = useState<string>();
  const [cardError, setCardError] =
    useState<Partial<{ [key in "cvv" | "number" | "expiration"]: string }>>();

  const scriptStatus = useExternal(
    isProduction ? "https://js.dlocal.com/" : "https://js-sandbox.dlocal.com/",
    { js: { defer: true }, type: "js", keepWhenUnused: true },
  );

  useEffect(() => {
    setIsPolling(paymentStatusData?.data.status === "PENDING");

    if (paymentStatusData?.data.status !== "PENDING") {
      message.destroy();
    }

    if (paymentStatusData?.data.status === "PAID") {
      notification.success({ message: "Card saved successfully" });
      onSubmit?.();
    }

    if (paymentStatusData?.data.status === "CANCELLED") {
      notification.info({
        message: "Card setup was cancelled",
      });
    }

    if (paymentStatusData?.data.status === "REJECTED") {
      notification.error({
        message: paymentStatusData.data.message,
        description:
          "Please try again, or contact your account manager or support@usesmileid.com for assistance.",
      });
    }
  }, [paymentStatusData?.data.status]);

  const createToken = async () => {
    try {
      const { token } = await dLocalInstance!.createToken(cvv!, {
        name: fullName,
      });
      return token as string;
    } catch (e) {
      type TokenError = {
        error: { code: number; param: string; message: string };
        message?: string;
      };

      if ((e as TokenError)?.error?.param) {
        setCardError({
          [(e as TokenError).error.param]: (e as TokenError).error.message,
        });
      }

      notification.error({
        message: (e as TokenError).error.message || (e as TokenError).message,
      });
      return null;
    }
  };

  const handleSubmit = async ({ name, documentId, cardId }: FormValues) => {
    const token = await createToken();
    if (!token) {
      return;
    }

    createPaymentMethod.mutate(
      {
        currency,
        country,
        card_type: cardType!,
        name,
        payment_method: "CARD",
        document_id: documentId,
        partner_id: partnerId,
        dlocal_token: token,
        card_id: cardId,
      },
      {
        onSuccess: (resp) => {
          if (resp.code === 100) {
            message.open({
              type: "loading",
              content: "Processing payment method...",
              duration: 0,
            });
            setPaymentRef(resp.paymentRef);
            return;
          }
          notification.success({ message: "Card saved successfully" });
          onSubmit?.();
        },
        onError: (e) => {
          notification.error({
            message: e.response?.data.error || "Unable to process card",
            description:
              "Please try again, or contact your account manager or support@usesmileid.com for assistance.",
            duration: 10,
          });
        },
      },
    );
  };

  useEffect(() => {
    if (scriptStatus === "ready" && window.dlocal && !dLocalInstance) {
      setDLocalInstance(window.dlocal(apiKey));
    }
  }, [scriptStatus]);

  useDeepCompareEffect(() => {
    if (!dLocalInstance) return;

    const fields = dLocalInstance.fields({
      locale: "en",
      country,
    });

    const cardNumberField = fields.create("pan", {
      style: dlocalInputStyle,
    });

    const expirationField = fields.create("expiration", {
      style: dlocalInputStyle,
    });

    const cvvField = fields.create("cvv", {
      style: dlocalInputStyle,
    });
    setCVV(cvvField);

    cardNumberField.mount(document.getElementById("cardNumber")!);
    expirationField.mount(document.getElementById("expiration")!);
    cvvField.mount(document.getElementById("cvv")!);

    cardNumberField.on("brand", ({ brand }) => {
      setCardType(brand);
    });
  }, [dLocalInstance]);

  if (scriptStatus === "loading") {
    return <Loader size="large" />;
  }

  return (
    <Form
      form={form}
      initialValues={{ country }}
      onFinish={handleSubmit}
      layout="vertical"
    >
      {country === "ZA" && (
        <Form.Item
          name="documentId"
          label="National ID"
          rules={[
            { required: true, message: "National ID is required" },
            {
              pattern: /^[0-9]{13}$/,
              message: "Invalid national ID",
            },
          ]}
        >
          <Input className="w-full" />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        label="Full name"
        rules={[
          {
            required: true,
            message: "Full name is required",
            whitespace: true,
          },
        ]}
      >
        <Input className="w-full" />
      </Form.Item>
      <Form.Item
        label="Card number"
        required
        validateStatus={cardError?.number ? "error" : undefined}
        help={cardError?.number}
      >
        <div
          id="cardNumber"
          className="h-8 border border-solid border-neutral-300 rounded-full p-2 box-border"
        />
      </Form.Item>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            label="Expiration"
            required
            validateStatus={cardError?.expiration ? "error" : undefined}
            help={cardError?.expiration}
          >
            <div
              id="expiration"
              className="h-8 border border-solid border-neutral-300 rounded-full p-2 box-border"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label="CVV"
            validateStatus={cardError?.cvv ? "error" : undefined}
            help={cardError?.cvv}
            required
          >
            <div
              id="cvv"
              className="h-8 border border-solid border-neutral-300 rounded-full p-2 box-border"
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="country" label="Country">
        <Select
          className="w-full"
          disabled
          options={supportedCountries.map((country) => ({
            label: getCountryName(country),
            value: country,
          }))}
        />
      </Form.Item>
      <Typography.Paragraph>
        By confirming your payment, you allow us to charge your card for future
        payments.
      </Typography.Paragraph>
      <Row justify="end">
        <Form.Item>
          <Button
            htmlType="submit"
            loading={createPaymentMethod.isPending}
            type="primary"
          >
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default DLocalSaveCardForm;
