import React from "react";

import { DashboardLayout } from "components/layout";
import NotFound from "./NotFound";

const NotFoundPage: React.FC = () => (
  <DashboardLayout>
    <NotFound />
  </DashboardLayout>
);

export default NotFoundPage;
