import React, { Component, createRef } from "react";
import * as d3 from "d3";
import * as d3Axis from "d3-axis";
import moment from "moment";

import chevronDownIcon from "resources/img/icons/chevron-down.svg";
import chevronUpIcon from "resources/img/icons/chevron-up.svg";

class LineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelectOptions: false,
      notShown: new Set(),
    };
    this.showSelectOptions = this.showSelectOptions.bind(this);
  }

  svgRef = createRef();

  componentDidMount() {
    this.drawChart();
    // make sure the user is an admin and they've not only chosen a single partner in the dropdown
    if (this.props.isAdminView && this.props.data.points.length !== 1) {
      this.addLegendSelect(this.props.height);
    }
  }

  componentDidUpdate(prevProps) {
    // we don't want to update on state changes
    if (this.props.data !== prevProps.data) {
      this.drawChart();
      if (this.props.isAdminView && this.props.data.points.length !== 1) {
        this.addLegendSelect(this.props.height);
      }
    }
  }

  showSelectOptions() {
    this.setState({ showSelectOptions: !this.state.showSelectOptions });
  }

  drawChart() {
    const { width } = this.props;
    const { height } = this.props;
    let xMin;
    let xMax;
    let yMin;
    let yMax;

    const data = this.props.data ? this.props.data : {};
    let points = data ? data.points : [];
    let overRideYMin;
    let overRideYMax;
    if (this.state.notShown.size > 0) {
      const tempData = JSON.parse(JSON.stringify(points));
      const remove = [];
      for (let i = 0; i < tempData.length; i += 1) {
        if (this.state.notShown.has(tempData[i].jobs[0].partner_id)) {
          remove.push(i);
        } else {
          tempData[i].jobs.forEach((point) => {
            if (!overRideYMax || overRideYMax < point.count) {
              overRideYMax = point.count;
            }
            if (
              (!overRideYMin && overRideYMin !== 0) ||
              overRideYMin > point.count
            ) {
              overRideYMin = point.count;
            }
          });
        }
      }
      remove.reverse().forEach((index) => {
        tempData.splice(index, 1);
      });
      points = tempData;
    }

    if (data) {
      xMax = data.x_max;
      xMin = data.x_min;
      yMax = overRideYMax || data.y_max;
      yMin = overRideYMin || data.y_min;
    }

    const xValues = [moment(xMax), moment(xMin)];
    const yValues = [yMin, yMax];

    const chart = d3.select(this.svgRef.current);
    chart.selectAll("*").remove();

    const xScale = d3.scaleTime().domain(xValues).range([width, 0]);

    const yScale = d3.scaleLinear().domain(yValues).range([height, 0]);

    points.forEach((dataset) => {
      const line = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((datapoint) => xScale(moment(datapoint.date)))
        .y((datapoint) => yScale(datapoint.count));

      const tooltip = chart
        .append("svg:foreignObject")
        .attr("class", "tooltip")
        .attr("width", 180)
        .attr("height", 80)
        .style("display", "none");

      this.appendLine(chart, line, dataset, tooltip);
      this.appendDot(chart, xScale, yScale, dataset, tooltip);
    });

    this.addAxis(chart, xScale, yScale, height);
    this.appendGridLines(chart, height, width, xScale, yScale);
  }

  appendGridLines(chart, height, width, xScale, yScale) {
    chart
      .append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0,${height})`)
      .call(this.makeXGridlines(xScale).tickSize(-height).tickFormat(""));

    chart
      .append("g")
      .attr("class", "grid")
      .call(this.makeYGridlines(yScale).tickSize(-width).tickFormat(""));
  }

  makeXGridlines(xScale) {
    return d3.axisBottom(xScale);
  }

  makeYGridlines(yScale) {
    return d3.axisLeft(yScale);
  }

  appendLine(chart, line, dataset, tooltip) {
    chart
      .append("svg:path")
      .attr("d", line(dataset.jobs))
      .data(dataset.jobs)
      .attr("id", `path_${dataset.jobs[0].partner_id}`)
      .attr(
        "class",
        `js-linechart__path linechart__path inactive p${dataset.jobs[0].partner_id}`,
      )
      .attr("data-active", true)
      .on("mouseover", (event, datapoint) => {
        this.embolden(datapoint);
        this.showTooltip(tooltip, datapoint, event);
      })
      .on("mousemove", (event, datapoint) => {
        this.embolden(datapoint, event);
        this.showTooltip(tooltip, datapoint, event);
      })
      .on("mouseout", (event, datapoint) => {
        this.unbold(datapoint);
        tooltip.transition().duration(200).style("display", "none");
      });
  }

  appendDot(chart, xScale, yScale, dataset, tooltip) {
    chart
      .selectAll("dot")
      .data(dataset.jobs)
      .enter()
      .append("circle")
      .attr("r", 3)
      .attr("cx", (datapoint) => xScale(moment(datapoint.date)))
      .attr("cy", (datapoint) => yScale(datapoint.count))
      .attr("id", `circle_${dataset.jobs[0].partner_id}`)
      .attr(
        "class",
        `js-linechart__circle linechart__circle inactive p${dataset.jobs[0].partner_id}`,
      )
      .on("mousemove", (event, datapoint) => {
        this.embolden(datapoint);
        this.showTooltip(tooltip, datapoint, event);
      })
      .on("mouseout", (event, datapoint) => {
        this.unbold(datapoint);
        tooltip.transition().duration(200).style("display", "none");
      });
  }

  addAxis(chart, xScale, yScale, height) {
    // set up and draw the scales
    const xAxisOpts = {
      orient: "Bottom",
      scale: xScale,
      translate: `translate(0, ${height})`,
      tickFormat: d3.timeFormat("%a, %d %b"),
      tickSize: 10,
    };

    const yAxisOpts = {
      orient: "Left",
      scale: yScale,
      translate: `translate(0, 0)`,
      tickFormat: null,
      tickSize: 10,
    };

    const renderAxis = (options) => {
      const axisType = `axis${options.orient}`;
      const axis = d3Axis[axisType]()
        .scale(options.scale)
        .tickSize(options.tickSize)
        .tickFormat(options.tickFormat)
        .tickValues(options.tickValues)
        .ticks(7)
        .tickPadding([5]);
      chart
        .append("svg:g")
        .attr("class", "axis")
        .attr("transform", options.translate)
        .call(axis);
    };

    [xAxisOpts, yAxisOpts].forEach((opts) => {
      renderAxis(opts);
    });
  }

  embolden(datapoint) {
    d3.select(`.linechart__path.p${datapoint.partner_id}`).attr(
      "class",
      `js-linechart__path linechart__path active p${datapoint.partner_id}`,
    );
    d3.selectAll(`.linechart__circle.p${datapoint.partner_id}`)
      .attr(
        "class",
        `js-linechart__circle linechart__circle active p${datapoint.partner_id}`,
      )
      .attr("r", 4);
  }

  unbold(datapoint) {
    d3.select(`.linechart__path.p${datapoint.partner_id}`).attr(
      "class",
      `js-linechart__path linechart__path inactive p${datapoint.partner_id}`,
    );
    d3.selectAll(`.linechart__circle.p${datapoint.partner_id}`)
      .attr(
        "class",
        `js-linechart__circle linechart__circle inactive p${datapoint.partner_id}`,
      )
      .attr("r", 3);
  }

  showTooltip(tooltip, datapoint, event) {
    let html;
    const mouseCoords = d3.pointer(event);
    let partnerName = "";
    let partnerInfo = "";
    if (this.props.isAdminView) {
      for (let i = 0; i < this.props.data.partner_ids.length; i += 1) {
        if (
          datapoint.partner_id === this.props.data.partner_ids[i].partner_id
        ) {
          partnerName = this.props.data.partner_ids[i].company;
          break;
        }
      }
    }
    partnerInfo = `Partner ID: ${datapoint.partner_id} ${partnerName}`;
    if (event.target.tagName === "path") {
      html = `<div xmlns='http://www.w3.org/1999/xhtml' class='tooltip--info'><style>.tooltip--info{background-color: #004071}</style>${partnerInfo}</div>`;
    } else {
      html = `<div xmlns='http://www.w3.org/1999/xhtml' class='tooltip--info'><style>.tooltip--info{background-color: #004071}</style>${partnerInfo}<br/>Date: ${moment(
        datapoint.date,
      ).format("ddd, DD MMM YYYY")}<br/>Count: ${datapoint.count}</div>`;
    }

    // The transform is based off of values set in our css. I could not find a way to determine these
    // on the fly rather than hard coding "magic numbers" ¯\_(ツ)_/¯
    tooltip
      .html(html)
      .attr(
        "transform",
        `translate(${mouseCoords[0]}, ${mouseCoords[1] - 105})`,
      )
      .transition()
      .duration(200)
      .style("display", "block");

    if (partnerName.length > 0) {
      tooltip.attr("height", 100).style("height", "100px");
    }
  }

  addLegendSelect() {
    //  a bit of a hack but to avoid duplicates when the component updates
    const chart = d3.select(this.svgRef.current);
    d3.selectAll(".js-legend__item").remove();

    d3.select("#selectButton")
      .selectAll("options")
      .data(["Select All"])
      .enter()
      .append("div")
      .text((datapoint) => datapoint) // text showed in the menu
      .attr("id", `legend_none`)
      .attr("class", "js-legend__item legend__item legend__item--all")
      .on("click", () => {
        const notShown = new Set();
        this.setState({
          notShown,
        });
        this.massUpdateChart(chart, false);
      });

    d3.select("#selectButton")
      .selectAll("options")
      .data(["Select None"])
      .enter()
      .append("div")
      .text((datapoint) => datapoint) // text showed in the menu
      .attr("id", `legend_none`)
      .attr("class", "js-legend__item legend__item legend__item--none")
      .on("click", () => {
        const notShown = new Set();
        this.props.data.partner_ids.forEach((partner_obj) => {
          notShown.add(partner_obj.partner_id);
        });
        this.setState({
          notShown,
        });
        this.massUpdateChart(chart, null, true);
      });

    const partner_obj = this.props.data.partner_ids;
    d3.select("#selectButton")
      .selectAll("options")
      .data(partner_obj)
      .enter()
      .append("div")
      .text((datapoint) => `${datapoint.partner_id} - ${datapoint.company}`) // text showed in the menu
      // .attr("value",  datapoint =>  { return datapoint; })
      .attr("id", (datapoint) => `legend_${datapoint.partner_id}`)
      .attr("class", "js-legend__item legend__item")
      .style("color", (datapoint) =>
        this.state.notShown.has(datapoint.partner_id) ? "#797979" : "#17A3DC",
      )
      .on("click", (event, datapoint) => {
        const { partner_id } = datapoint;
        const active = !this.state.notShown.has(partner_id);
        this.updateChart(chart, partner_id, active);
      });
  }

  updateChart(chart, partner_id, active) {
    const { notShown } = this.state;
    if (active) {
      notShown.add(partner_id);
    } else {
      notShown.delete(partner_id);
    }
    this.setState({
      notShown,
    });

    // hide or show the elements
    const newColor = active ? "#797979" : "#17A3DC";
    d3.select(`#legend_${partner_id}`).style("color", newColor);
    this.drawChart();
  }

  massUpdateChart(chart, active) {
    // hide or show the elements
    const newColor = active ? "#797979" : "#17A3DC";
    this.props.data.partner_ids.forEach((partner_obj) => {
      d3.select(`#legend_${partner_obj.partner_id}`).style("color", newColor);
    });
    this.drawChart();
  }

  render() {
    const { width } = this.props;
    const { height } = this.props;

    return (
      <div className="chart__container">
        {this.props.isAdminView && this.props.data.points.length !== 1 && (
          <div className="linechart__select-container">
            <div
              className="linechart__select-button"
              onClick={this.showSelectOptions}
              style={
                this.state.showSelectOptions
                  ? { borderRadius: "4px 4px 0 0" }
                  : { borderRadius: "4px" }
              }
            >
              Select Some Partners{" "}
              <img
                src={
                  this.state.showSelectOptions ? chevronUpIcon : chevronDownIcon
                }
                alt="chevron"
              />
            </div>
            <div
              style={
                this.state.showSelectOptions
                  ? { display: "block" }
                  : { display: "none" }
              }
              className="linechart__select-options"
              id="selectButton"
            />
          </div>
        )}

        <svg
          className="linechart"
          width={width}
          height={height}
          ref={this.svgRef}
        />
      </div>
    );
  }
}

export default LineChart;
